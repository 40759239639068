

#Home {
	width: 100%;
	min-height: calc( 100vh - 90px ); /* TEMPORARY SOLUTION, topbar (50px) + content padding (2 x 20px) */
}



#Home .stats {
	font-size: 16px;
	margin: 0 0 2em;
}

#Home .stats:after {
	content: '';
	display: block;
	clear: both;
}

#Home .stats .stat {
	width: 23.5%;
	height: 90px;
	margin: 0 2% 1% 0;
	padding: 10px 10px 10px 50px;
	float: left;
	background: #f9f9f9;
	border-bottom: 5px solid #ccc;
	position: relative;
}
#Home .stats .stat:nth-child(4n+0) { margin-right: 0; }

#Home .stats .stat svg {
	position: absolute;
	top: 50%;
	left: 12px;
	width: 24px;
	height: 24px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#Home .stats .stat .title {
	font-family: 'Roboto', Arial, sans-serif;
	font-size: 90%;
	line-height: 20px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
#Home .stats .stat .value {
	font-size: 140%;
	line-height: 30px;
}
#Home .stats .stat .value small {
	font-size: 70%;
}

#Home .stats .stat .info {
	font-size: 90%;
	line-height: 20px;
	color: #999;
	text-align: right;
}

#Home .stats .stat.loading .value,
#Home .stats .stat.loading .info { opacity: 0.3; }

#Home .blockContent {
	min-height: 100px;
	position: relative;
}

#Home .list {
	padding: 1em;
}

#Home .list .listItem {
	display: block;
	border-bottom: 1px solid #eee;
	padding: 0.5em 1em;
	line-height: 1.2em;
	color: inherit;
	cursor: pointer;
	position: relative;
}

#Home .list .listItem:hover {
	text-decoration: none;
	background: #f9f9f9;
}
#Home .list .listItem svg.arrow,
#Home .linkBox svg.arrow {
	position: absolute;
	top: 50%;
	right: 1em;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#Home .timetrackingEntries { position: relative; }

#Home .timetrackingEntries .list .listItem { padding-left: 40px; text-decoration: none; }
#Home .timetrackingEntries .list .listItem svg.type,
#Home .list.latestCrmEvents .listItem svg.type {
	position: absolute;
	top: 50%;
	left: 10px;
	width: 20px;
	height: 20px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#Home .timetrackingEntries .list.rejected .listItem { color: #CC0000; border-left: 3px solid #f00; }

#Home .list.latestCrmEvents .listItem { padding-left: 60px; }
#Home .list.latestCrmEvents .listItem svg.type {
	left: 1em;
	width: 32px;
	height: 32px;
	padding: 8px;
	background: #999;
	fill: #fff;
	border-radius: 999px;
}
#Home .list.latestCrmEvents .listItem svg.type.call { background: #2BBBAD; }
#Home .list.latestCrmEvents .listItem svg.type.email { background: #4285F4; }
#Home .list.latestCrmEvents .listItem svg.type.visit { background: #aa66cc; }


#Home .linkBox {
	display: inline-block;
	border: 1px solid #eee;
	border-left: 3px solid #ccc;
	padding: 1em 60px;
	margin: 1em;
	position: relative;
	text-decoration: none;
	color: #555;
}

#Home .linkBox:hover {
	text-decoration: none;
	background: #f9f9f9;
}

#Home .linkBox > svg:first-child {
	position: absolute;
	left: 10px;
	top: 50%;
	width: 40px;
	height: 40px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

#Home .linkBox span {
	font-size: 16px;
	line-height: 20px;
}

#Home .linkBox span > strong {
	display: block;
	font-size: 20px;
	line-height: 20px;
}


@media all and (max-width: 500px) {

	#Home .stats .stat {
		width: 100%;
		margin: 0 0 1% 0;
	}
}
