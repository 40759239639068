.row-padding {
    padding-bottom: 10px;
}

.white-space {
    white-space: pre-wrap;
}

.pdf-canvas canvas {
    max-width: 100%;
    height: auto !important;
}
