@font-face {
    font-family: 'Roboto-Light';
    src: url('/fonts/Roboto-Light.woff') format('woff'),
         url('/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Regular.woff') format('woff'),
         url('/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto-Mono';
    src: url('/fonts/RobotoMono-Light.woff') format('woff'),
         url('/fonts/RobotoMono-Light.ttf') format('truetype');
}


body {
    font-family: 'Roboto-Light', Arial, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5em;

    text-size-adjust:           none;
    -webkit-text-size-adjust:   none;

    background-color: #f5f5f5;
    color: #666;
}

h1,
h2, 
h3, 
h4, 
h5,
h6 {
    margin: 0.5em 0 0.2em;
    line-height: 1em;
}

h1 { font-size: 200%; color: #0099CC; }
h2 { font-size: 180%; }
h3 { font-size: 160%; }
h4 { font-size: 140%; }
h5 { font-size: 120%; font-family: 'Roboto', Arial, sans-serif; }
h6 { font-size: 100%; font-family: 'Roboto', Arial, sans-serif; }

.formHeader {
    line-height: 1.5em;
    margin: 0 0 0.5em;
    border-bottom: 3px solid #eee;
    position: relative;
}

.formHeader .headerAddon {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

hr {
    margin: 1em 0;
    padding: 0;
    border: 0;
    border-top: 1px solid #ccc;
}

hr.half {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

strong {
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: normal;
}

.padding { padding: 1em; }
.padding-small { padding: 0.5em; }
.padding-large { padding: 2em; }

.no-margin { margin: 0; }
.no-padding { padding: 0; }
.no-border { border: 0; }

.center { text-align: center; }
.text-right { text-align: right; }
.text-left { text-align: left; }
.pointer { cursor: pointer; }
.floatLeft { float: left; }
.bold { font-weight: 700; }
.relative{ position: relative; }


.clear:after {
    content: '';
    display: block;
    clear: both; 
}


.pageBackButton {
    display: inline-block;
    padding: 0.5em 1em;
    margin-bottom: -1em;

    font-size: 14px;
    line-height: 1em;

    cursor: pointer;
}

.pageBackButton:hover {
    color: #999;
}

.pageBackButton svg {
    width: 16px;
    left: 16px;
    margin: -3px 5px 0 0;
}




.apBadge {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 12px;
    line-height: 1em;
    
    padding: 3px 5px;
    background: #999;
    color: #fff;

    -webkit-border-radius: 999px;
            border-radius: 999px;
}

.apBadge.faded { opacity: 0.5; }
.apBadge.dark {     background: #555; }
.apBadge.blue {     background: #0099CC; }
.apBadge.teal {     background: #4db6ac; }
.apBadge.red {      background: #ff4444; }
.apBadge.green {    background: #388e3c; }
.apBadge.orange {   background: #FF8800; }
.apBadge.violet {   background: #aa66cc; }
.apBadge.black {    background: #000; }

.apMsg,
.apErrorMsg,
.apWarningMsg,
.apInfoMsg,
.apSuccessMsg {
    margin: 0 0 0.5em;
    padding: 0.5em;
    background: #eee;
    color: #aaa;
    font-size: 14px;
    line-height: 1.5em;
    text-align: center;
    -webkit-border-radius: 10px;
            border-radius: 10px;
}

.apErrorMsg {
    background: #ffcdd2;
    color: #CC0000;
}

.apWarningMsg {
    background: #ffe0b2;
    color: #e65100;
}

.apInfoMsg {
    background: #e3f2fd;
    color: #0099CC;
}

.apSuccessMsg {
    background: #dcedc8;
    color: #007E33;
}


.apSimpleButton {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
}
.apSimpleButton:focus { outline: 0; }
.apSimpleButton:hover { background: rgba(0,0,0,0.025); }
.apSimpleButton.disabled,
.apSimpleButton:disabled { pointer-events: none; }

.apSimpleButton.toggled {
    box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
    background: rgba(0,0,0,0.1);
}

.apSimpleButton svg.SvgIcon {
    display: block;
    position: absolute;
    top: 30%;
    left: 30%;
    width: 40%;
    height: 40%;
}
.apSimpleButton:focus svg.SvgIcon,
.apSimpleButton:hover svg.SvgIcon { fill: #33b5e5; }

.apSimpleButton.disabled svg,
.apSimpleButton:disabled svg { opacity: 0.2; }


.apSimpleButton .apBadge {
    position: absolute;
    top: 5px;
    right: 5px;
}

.apSimpleButton.addon { height: 42px; }

.apOptionBar {
    width: 100%;
}

.apOptionBar:after {
    content: '';
    display: block;
    clear: both;
}

.apOptionBar .option {
    width: auto;
    float: left;
    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    border-right: none;
    margin: 0;
    padding: 0.2em 0.5em;
    text-align: center;
    cursor: pointer;
}

.apOptionBar .option:hover {
    border-bottom-color: #33b5e5;
}

.apOptionBar .option.selected {
    border-bottom-color: #33b5e5;
    background: #e3f2fd;
    color: #0099CC;
}

.apOptionBar .option.disabled,
.apOptionBar.disabled .option {
    border-bottom-color: #ccc;
    background: #f0f0f0;
    color: #ccc;
    cursor: not-allowed;
}

.apOptionBar .option:first-child {

-webkit-border-top-left-radius: 5px;
        border-top-left-radius: 5px;

-webkit-border-bottom-left-radius: 5px;
        border-bottom-left-radius: 5px;
}

.apOptionBar .option:last-child {
    border-right: 1px solid #ccc;

-webkit-border-top-right-radius: 5px;
        border-top-right-radius: 5px;

-webkit-border-bottom-right-radius: 5px;
        border-bottom-right-radius: 5px;
}

.apOptionBar.count-1 .option { width: 100%; }
.apOptionBar.count-2 .option { width: 50%; }
.apOptionBar.count-3 .option { width: 33.33%; }
.apOptionBar.count-4 .option { width: 25%; }
.apOptionBar.count-5 .option { width: 20%; }
.apOptionBar.count-6 .option { width: 16.66%; }
.apOptionBar.count-7 .option { width: 14.28%; }
.apOptionBar.count-8 .option { width: 12.5%; }



.apItemList {
    width: 100%;
    min-height: 100px;
    max-height: 600px;
    background: #eee;

    box-shadow: inset 0 5px 20px rgba(0,0,0,0.2);
    padding: 1em;

    line-height: 1.2em;

    overflow: auto;
    position: relative;
    border: 1px solid #ccc;
}

.apItemList .item,
.apItemListDragItem {
    background: #fff;
    border-left: 5px solid #eee;
    margin: 0 0 0.5em;
    padding: 0.5em 1em;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
    position: relative;
    line-height: 1.2em;
}

.apItemListDragItem {
    z-index: 999px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.4);
}

.apItemList .item.clickable,
.apItemListDragItem.clickable {
    cursor: pointer;
    border-left-color: #0099CC;
}
.apItemList .item.clickable:hover { border-left-color: #33b5e5; }

.apItemList .item.unsaved,
.apItemListDragItem.unsaved { border-left-color: #FF8800; }

.apItemList .item.unsaved.clickable:hover { border-left-color: #ffbb33; }

.apItemList .item.removed,
.apItemListDragItem.removed {
    border-left-color: #CC0000;
    background: #eee;
    color: #999;
    text-decoration: line-through;
}
.apItemList .item.removed.clickable:hover { border-left-color: #ff4444; }

.apItemList .item > .name,
.apItemListDragItem > .name {
    padding-right: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.apItemList .item.withIcon > .name,
.apItemListDragItem.withIcon > .name { padding-left: 25px; }

.apItemList .item.withIcon > svg,
.apItemList .item > .info,
.apItemList .item > .apDropdown,
.apItemListDragItem.withIcon > svg,
.apItemListDragItem > .info,
.apItemListDragItem > .apDropdown {
    position: absolute;
    z-index: 2;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.apItemList .item.withIcon > svg,
.apItemListDragItem.withIcon > svg { 
    left: 10px; 
    width: 20px;
    height: 20px;
}

.apItemList .item.draggable > .dragHandle,
.apItemListDragItem.draggable > .dragHandle { 
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0; 
    bottom: 0;
    width: 0px;
    overflow: hidden;
    background: #eee;
    color: #666; 

    -webkit-transition: width 0.4s ease;
       -moz-transition: width 0.4s ease;
        -ms-transition: width 0.4s ease;
         -o-transition: width 0.4s ease;
            transition: width 0.4s ease;
}
.apItemList .item.draggable > .dragHandle:hover { background: #e9e9e9; }

.apItemList .item.draggable:hover > .dragHandle,
.apItemListDragItem.draggable > .dragHandle { width: 32px; }
.apItemListDragItem.draggable > .dragHandle { background: #ddd; }

.apItemList .item.draggable > .dragHandle svg,
.apItemListDragItem.draggable > .dragHandle svg { 
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
         -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
}

.apItemList .item > .info,
.apItemListDragItem > .info {
    right: 80px;
    background: #fff;
    padding-left: 1em;
    border-left: 1px solid #eee;
}

.apItemList .item.removed > .info,
.apItemListDragItem.removed > .info { background: #eee; border-left-color: #ccc; }

.apItemList .item > .apDropdown,
.apItemListDragItem > .apDropdown {
    z-index: 3;
    right: 0.5em;
}
.apItemList .item > .apDropdown.open,
.apItemListDragItem > .apDropdown.open { z-index: 4; }


.apItemList .header,
.apItemList .footer {
    text-align: center;
    font-size: 14px;
    margin: 1em 0;
    padding: 0.2em 0;
}
.apItemList .header { border-bottom: 1px solid #ccc; padding-top: 2em; }
.apItemList .footer { border-top: 1px solid #ccc; padding-bottom: 2em; }




.apStatusBall {
    display: inline-block;
    width: 12px;
    height: 12px;
    vertical-align: middle;
    border: 3px solid transparent;
    margin-right: 10px;
    background: transparent;
    -webkit-border-radius: 10px;
            border-radius: 10px;
}

.apStatusBall.success   { background: #007E33; border-color: #dcedc8; }
.apStatusBall.warning   { background: #e65100; border-color: #ffe0b2; }
.apStatusBall.error     { background: #CC0000; border-color: #ffcdd2; }
.apStatusBall.info      { background: #0099CC; border-color: #e3f2fd; }
.apStatusBall.disabled  { background: #ccc; border-color: #eee; }


.apStatusBox {
    display: block;
    width: 100%;
    background: #f9f9f9;
    color: #aaa;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
}

.apStatusBox.disabled  { color: #666; background: #ddd; }
.apStatusBox.success   { color: #007E33; background: #dcedc8; }
.apStatusBox.warning   { color: #e65100; background: #ffe0b2; }
.apStatusBox.error     { color: #CC0000; background: #ffcdd2; }
.apStatusBox.info      { color: #0099CC; background: #e3f2fd; }

.apStatusBox svg.SvgIcon {
    position: absolute;
    top: 50%;
    left: 5px;
    width: 16px;
    height: 16px;
    margin-top: -8px;
}


.apInfo {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    line-height: 1.5em;
    margin: 0 0 1em;
}

.apInfo svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
}

.apInfo.small { padding-left: 24px; font-size: 14px; }
.apInfo.small svg { width: 16px; height: 16px; top: 2px; left: 2px; }

.apInfo.warning svg { fill: #FF8800; }
.apInfo.error svg { fill: #ff4444; }
.apInfo.success svg { fill: #4caf50; }

.apBox {
    background: #fff;
    margin: 1em 0;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

.apBox .apBoxHeader {
    padding: 0.2em 1em;
    border-bottom: 1px solid #ccc;
    position: relative;
}

.apBox .apBoxHeader h1 svg,
.apBox .apBoxHeader h2 svg,
.apBox .apBoxHeader h3 svg,
.apBox .apBoxHeader h4 svg,
.apBox .apBoxHeader h5 svg,
.apBox .apBoxHeader h6 svg {
    margin: -5px 10px 0 0;
}

.apBox .apBoxHeader .apBoxCorner {
    position: absolute;
    top: 5px;
    right: 5px;
}




.apForm {
    margin: 1em 0;
}

.apForm:after {
    content: '';
    display: block;
    clear: both;
}

.apFormHeader {
    text-align: left;
    margin: /* 1em 2% 0.5em*/ 0 0 0.5em;
    padding: 0 0 0.2em;
    color: #33b5e5;
    border-bottom: 1px solid #33b5e5;
}

.apFormColumn .apFormHeader { margin: 0 0 0.5em; }

.apForm .apFormColumn {
    width: 46%;
    margin: 0 2%;
    float: left;
}

.apForm .apFormColumn.fullColumn { 
    width: auto;
    float: none;
}

.apForm .apFormColumn.singleColumn { 
    float: none;
    margin: 0 auto; 
}

@media all and (max-width: 699px) {
    .apForm .apFormColumn {
        width: 98%;
        float: none;
    }
}

.apColumn {
    float: left;
    width: 100%;
    margin: 0;
}
.apColumn.right { float: right; }

.apColumn.w10 { width: 10%; }
.apColumn.w20 { width: 20%; }
.apColumn.w25 { width: 25%; }
.apColumn.w30 { width: 30%; }
.apColumn.w33 { width: 33.33%; }
.apColumn.w40 { width: 40%; }
.apColumn.w50 { width: 50%; }
.apColumn.w60 { width: 60%; }
.apColumn.w66 { width: 66.66%; }
.apColumn.w70 { width: 70%; }
.apColumn.w75 { width: 75%; }
.apColumn.w80 { width: 80%; }
.apColumn.w90 { width: 90%; }



.apFormGroup {
    border: 1px solid #eee;
    border-left: 5px solid #eee;
    padding: 1em;
    margin: 0 0 1em;
    box-shadow: 0 5px 10px rgba(0,0,0,0.05);

    -webkit-transition: border-color 0.4s ease;
       -moz-transition: border-color 0.4s ease;
        -ms-transition: border-color 0.4s ease;
         -o-transition: border-color 0.4s ease;
            transition: border-color 0.4s ease;
}

.apFormGroup.success   { border-left-color: #00C851; }
.apFormGroup.warning   { border-left-color: #ffbb33; }
.apFormGroup.error     { border-left-color: #ff4444; }
.apFormGroup.info      { border-left-color: #33b5e5; }
.apFormGroup.disabled  { border-left-color: #ccc; background: #f9f9f9; pointer-events: none; }









.apInputSwitch {
    display: block;
    margin: 0 0 1em;
    padding: 0.5em 1em 0.5em 60px;
    background: #f9f9f9;
    border-left: 3px solid #ccc;
    position: relative;
}

.apInputSwitch .apSwitchWrapper {
    position: absolute;
    top: 50%;
    left: 10px;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}


.apListBlock {
    margin: 0 0 0.5em;
    padding-left: 20px;
    border: 1px solid #eee;
    position: relative;
}

.apListBlock:hover {
    border-color: #ccc;
}

.apListBlock .apListIcon {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    padding: 0 5px;
    height: 100%;
    background: #eee;
}

.apListBlock .text {
    line-height: 1.2em;
}
.apListBlock .status {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100px;
}



.apPersonBlock {
    display: inline-block;
    width: 180px;
    height: 150px;
    margin: 60px 10px 10px;
    padding: 0 10px;
    vertical-align: top;

    border: 1px solid #eee;
}

.apPersonBlock:hover { border-color: #ccc; }
.apPersonBlock.status-info      { border-color: #0099CC; background: #e3f2fd;}
.apPersonBlock.status-warning   { border-color: #e65100; background: #fff3e0;}
.apPersonBlock.status-error     { border-color: #ff4444; background: #ffebee;}

.apPersonBlock .image {
    width: 100px;
    height: 100px;
    background: #ccc;
    border: 2px solid #fff;
    border-radius: 999px;
    margin: -50px auto 0;

    box-shadow: 0 0 10px rgba(0,0,0,0.5);

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.apPersonBlock .text {
    margin: 0.5em 0 0;
    text-align: center;
    line-height: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.apPersonBlock .actions {
    margin: 0.5em 0 0;
}

.apPersonBlock .actions .action {
    display: inline-block;
    margin: 0 10px;
    position: relative;
    opacity: 0.5;
    cursor: pointer;

    -webkit-transition: opacity 0.4s ease;
       -moz-transition: opacity 0.4s ease;
        -ms-transition: opacity 0.4s ease;
         -o-transition: opacity 0.4s ease;
            transition: opacity 0.4s ease;
}

.apPersonBlock:hover .actions .action,
.apPersonBlock .actions .action.error,
.apPersonBlock .actions .action.warning,
.apPersonBlock .actions .action.info { opacity: 1; }

.apPersonBlock .actions .action.error svg   { fill: #ff4444; }
.apPersonBlock .actions .action.warning svg { fill: #e65100; }
.apPersonBlock .actions .action.info svg    { fill: #0099CC; }

.apSwitchBlock {
    display: block;
    position: relative;
}

.apSwitchBlock .info {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 16px;
    line-height: 20px;
    padding: 0.5em 0;
    width: calc( 100% - 120px );
}

.apSwitchBlock label.info:hover {
    color: #0099CC;
    cursor: pointer;
}

.apSwitchBlock .info small {
    font-family: 'Roboto-Light', Arial, sans-serif;
    display: block;
}

.apSwitchBlock .apSwitchWrapper {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.apSwitchBlock.small .info { padding: 0; width: calc( 100% - 60px ); }


.border {
    border: 1px solid #CCCCCC;
}

.white {
    background-color: #ffffff;
    color: #444444;
    border-radius: 5px;
    margin-bottom: 20px;
}

.pdfbg {
    border: 1px solid;
    border-bottom: 0;
    margin: 1em 1em 0 1em;
    background-color: gray;
    padding: 1em;
}

.table-black {
    color: #000000;
}
.row-header {
    color: #000000;
    padding-left: 15px;
}

.row.padding{
    padding: 10px;
}
.center-content {
    text-align: center;
}
.top-margin {
    margin-top: 20px;
}
.breadcrumb {
    position: relative;
    border-bottom: 1px solid #c2cfd6;
}

h1.smaller {
    color: #000000;
    font-size: 3rem;
}
.white-bg {
    background-color: #ffffff;
}

.header-dropdown {
    margin: 6px;
}

.content {
    padding: 20px;
    width:100%;
}


.alert-danger {
    background-color: #dd4b39 !important;
    color: #ffffff;
}

.width90 {
    width: 90%;
}

.savebox {
    box-shadow: 0 0 1em rgba(0, 0, 0, .5);
    border-color: #BBDEFB;
    background-color: white;
    bottom: -1px;
    right: 10%;
    position: fixed;
    padding: 0.5em 0.5em 0.5em 0.5em;
    border-radius: 5px 5px 0 0;
    z-index: 600;
}

.savebox-left {
    box-shadow: 0 0 1em rgba(0, 0, 0, .5);
    border-color: #BBDEFB;
    background-color: white;
    bottom: -1px;
    left: 20%;
    position: fixed;
    padding: 0.5em 0.5em 0.5em 0.5em;
    border-radius: 5px 5px 0 0;
    z-index: 600;
}


.list-container {
    background-color: #FFF;
    boxShadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid #CCC;
    borderRadius: 3px;
    padding: 10px;
    position: absolute;
    z-index: 1060!important;
}

.list-item {
    padding: 4px 0px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
}
.list-code {
    font-weight: bold;
    padding-left: 1em;
}

@keyframes flash {
    0% { opacity: 1; } 
    50% { opacity: .1; } 
    100% { opacity: 1; }
}
.flashit {
    animation: flash linear 1s infinite;
}

.hovershadow:hover {
    text-shadow: 0 0  2px #000;
}

.storagedatabox {
    background-color: white;
    border: 1px solid;
    border-radius: 10%;
    padding: 0 1px;
    margin-right: 2px;
    cursor: pointer;
}

.ApModalConfirm .footer {
    float: right;
}
.ApModalConfirm  .footer button {
    margin: 0 0.2em;
}


.apInlineLoader {
    display: inline-block;
    width: 20px;
    height: 16px;
    vertical-align: middle;
    margin: 0 5px;
    position: relative;
}

.apLoader,
.apInlineLoader:after {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -10px;
    -webkit-border-radius: 999px;
            border-radius: 999px;
    border: 3px solid rgba(0,0,0,0.5);
    border-left-color: transparent;
    border-top-color: transparent;

    -webkit-animation: apSpinAnimation 1s infinite;
       -moz-animation: apSpinAnimation 1s infinite;
         -o-animation: apSpinAnimation 1s infinite;
        -ms-animation: apSpinAnimation 1s infinite;
            animation: apSpinAnimation 1s infinite;
}

.apInlineLoader:after {
    content: '';
    width: 16px;
    height: 16px;
}

.justify-end {
    display: flex;
    justify-content: flex-end;
}

.justify-space-between {
    display: flex;
    justify-content: space-between;
}

.rotate-anti-clockwise-90 {
    transform: rotate(-90deg);
}

.bg-light-green {
    background-color: #eef8e7 !important;
}
.apDropdown .bg-light-green.action:hover {
    background-color: #eef8e7 !important;
}


@keyframes apSpinAnimation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-moz-keyframes apSpinAnimation {
    0% { -moz-transform: rotate(0deg); }
    100% { -moz-transform: rotate(360deg); }
}

@-webkit-keyframes apSpinAnimation {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@-o-keyframes apSpinAnimation {
    0% { -o-transform: rotate(0deg); }
    100% { -o-transform: rotate(360deg); }
}

@-ms-keyframes apSpinAnimation {
    0% { -ms-transform: rotate(0deg); }
    100% { -ms-transform: rotate(360deg); }
}

@media all and (max-width: 800px) {

    h1 { font-size: 180%; }
    h2 { font-size: 160%; }
    h3 { font-size: 140%; }
    h4 { font-size: 120%; }
    h5 { font-size: 110%; }
    h6 { font-size: 100%; }

}


@media all and (max-width: 600px) {

    body {
        font-size: 15px;
    }

    h1 { font-size: 160%; }
    h2 { font-size: 140%; }
    h3 { font-size: 120%; }
    h4 { font-size: 110%; }
    h5 { font-size: 105%; }
    h6 { font-size: 100%; }

    .content {
        padding: 10px;
    }

    .padding { padding: 0.5em; }
    .padding-small { padding: 0.2em; }
    .padding-large { padding: 1em; }


}


@media all and (max-width: 400px) {

    h1 { font-size: 150%; }
    h2 { font-size: 130%; }
    h3 { font-size: 110%; }
    h4 { font-size: 105%; }
    h5 { font-size: 100%; }
    h6 { font-size: 100%; }

}


.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.pre {
    white-space: pre;
}




/* FEW HELPER CLASSES FOR MOBILE LAYOUTS */

.showUnder1500,
.showUnder1500i,
.showUnder1500ib,
.showUnder1400,
.showUnder1400i,
.showUnder1400ib,
.showUnder1300,
.showUnder1300i,
.showUnder1300ib,
.showUnder1200,
.showUnder1200i,
.showUnder1200ib,
.showUnder1100,
.showUnder1100i,
.showUnder1100ib,
.showUnder1000,
.showUnder1000i,
.showUnder1000ib,
.showUnder950,
.showUnder950i,
.showUnder950ib,
.showUnder900,
.showUnder900i,
.showUnder900ib,
.showUnder850,
.showUnder850i,
.showUnder850ib,
.showUnder800,
.showUnder800i,
.showUnder800ib,
.showUnder750,
.showUnder750i,
.showUnder750ib,
.showUnder700,
.showUnder700i,
.showUnder700ib,
.showUnder650,
.showUnder650i,
.showUnder650ib,
.showUnder600,
.showUnder600i,
.showUnder600ib,
.showUnder550,
.showUnder550i,
.showUnder550ib,
.showUnder500,
.showUnder500i,
.showUnder500ib,
.showUnder450,
.showUnder450i,
.showUnder450ib,
.showUnder400,
.showUnder400i,
.showUnder400ib,
.showUnder350,
.showUnder350i,
.showUnder350ib { display: none; }

@media all and (max-width: 1500px) { .hideUnder1500 { display: none !important; } }
@media all and (max-width: 1400px) { .hideUnder1400 { display: none !important; } }
@media all and (max-width: 1300px) { .hideUnder1300 { display: none !important; } }
@media all and (max-width: 1200px) { .hideUnder1200 { display: none !important; } }
@media all and (max-width: 1100px) { .hideUnder1100 { display: none !important; } }
@media all and (max-width: 1000px) { .hideUnder1000 { display: none !important; } }
@media all and (max-width: 950px) { .hideUnder950 { display: none !important; } }
@media all and (max-width: 900px) { .hideUnder900 { display: none !important; } }
@media all and (max-width: 850px) { .hideUnder850 { display: none !important; } }
@media all and (max-width: 800px) { .hideUnder800 { display: none !important; } }
@media all and (max-width: 750px) { .hideUnder750 { display: none !important; } }
@media all and (max-width: 700px) { .hideUnder700 { display: none !important; } }
@media all and (max-width: 650px) { .hideUnder650 { display: none !important; } }
@media all and (max-width: 600px) { .hideUnder600 { display: none !important; } }
@media all and (max-width: 550px) { .hideUnder550 { display: none !important; } }
@media all and (max-width: 500px) { .hideUnder500 { display: none !important; } }
@media all and (max-width: 450px) { .hideUnder450 { display: none !important; } }
@media all and (max-width: 400px) { .hideUnder400 { display: none !important; } }
@media all and (max-width: 350px) { .hideUnder350 { display: none !important; } }

@media all and (max-width: 1500px) { .showUnder1500 { display: block !important; } }
@media all and (max-width: 1400px) { .showUnder1400 { display: block !important; } }
@media all and (max-width: 1300px) { .showUnder1300 { display: block !important; } }
@media all and (max-width: 1200px) { .showUnder1200 { display: block !important; } }
@media all and (max-width: 1100px) { .showUnder1100 { display: block !important; } }
@media all and (max-width: 1000px) { .showUnder1000 { display: block !important; } }
@media all and (max-width: 950px) { .showUnder950 { display: block !important; } }
@media all and (max-width: 900px) { .showUnder900 { display: block !important; } }
@media all and (max-width: 850px) { .showUnder850 { display: block !important; } }
@media all and (max-width: 800px) { .showUnder800 { display: block !important; } }
@media all and (max-width: 750px) { .showUnder750 { display: block !important; } }
@media all and (max-width: 700px) { .showUnder700 { display: block !important; } }
@media all and (max-width: 650px) { .showUnder650 { display: block !important; } }
@media all and (max-width: 600px) { .showUnder600 { display: block !important; } }
@media all and (max-width: 550px) { .showUnder550 { display: block !important; } }
@media all and (max-width: 500px) { .showUnder500 { display: block !important; } }
@media all and (max-width: 450px) { .showUnder450 { display: block !important; } }
@media all and (max-width: 400px) { .showUnder400 { display: block !important; } }
@media all and (max-width: 350px) { .showUnder350 { display: block !important; } }

@media all and (max-width: 1500px) { .showUnder1500i { display: inline !important; } }
@media all and (max-width: 1400px) { .showUnder1400i { display: inline !important; } }
@media all and (max-width: 1300px) { .showUnder1300i { display: inline !important; } }
@media all and (max-width: 1200px) { .showUnder1200i { display: inline !important; } }
@media all and (max-width: 1100px) { .showUnder1100i { display: inline !important; } }
@media all and (max-width: 1000px) { .showUnder1000i { display: inline !important; } }
@media all and (max-width: 950px) { .showUnder950i { display: inline !important; } }
@media all and (max-width: 900px) { .showUnder900i { display: inline !important; } }
@media all and (max-width: 850px) { .showUnder850i { display: inline !important; } }
@media all and (max-width: 800px) { .showUnder800i { display: inline !important; } }
@media all and (max-width: 750px) { .showUnder750i { display: inline !important; } }
@media all and (max-width: 700px) { .showUnder700i { display: inline !important; } }
@media all and (max-width: 650px) { .showUnder650i { display: inline !important; } }
@media all and (max-width: 600px) { .showUnder600i { display: inline !important; } }
@media all and (max-width: 550px) { .showUnder550i { display: inline !important; } }
@media all and (max-width: 500px) { .showUnder500i { display: inline !important; } }
@media all and (max-width: 450px) { .showUnder450i { display: inline !important; } }
@media all and (max-width: 400px) { .showUnder400i { display: inline !important; } }
@media all and (max-width: 350px) { .showUnder350i { display: inline !important; } }

@media all and (max-width: 1500px) { .showUnder1500ib { display: inline-block !important; } }
@media all and (max-width: 1400px) { .showUnder1400ib { display: inline-block !important; } }
@media all and (max-width: 1300px) { .showUnder1300ib { display: inline-block !important; } }
@media all and (max-width: 1200px) { .showUnder1200ib { display: inline-block !important; } }
@media all and (max-width: 1100px) { .showUnder1100ib { display: inline-block !important; } }
@media all and (max-width: 1000px) { .showUnder1000ib { display: inline-block !important; } }
@media all and (max-width: 950px) { .showUnder950ib { display: inline-block !important; } }
@media all and (max-width: 900px) { .showUnder900ib { display: inline-block !important; } }
@media all and (max-width: 850px) { .showUnder850ib { display: inline-block !important; } }
@media all and (max-width: 800px) { .showUnder800ib { display: inline-block !important; } }
@media all and (max-width: 750px) { .showUnder750ib { display: inline-block !important; } }
@media all and (max-width: 700px) { .showUnder700ib { display: inline-block !important; } }
@media all and (max-width: 650px) { .showUnder650ib { display: inline-block !important; } }
@media all and (max-width: 600px) { .showUnder600ib { display: inline-block !important; } }
@media all and (max-width: 550px) { .showUnder550ib { display: inline-block !important; } }
@media all and (max-width: 500px) { .showUnder500ib { display: inline-block !important; } }
@media all and (max-width: 450px) { .showUnder450ib { display: inline-block !important; } }
@media all and (max-width: 400px) { .showUnder400ib { display: inline-block !important; } }
@media all and (max-width: 350px) { .showUnder350ib { display: inline-block !important; } }


