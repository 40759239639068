

.block.user .topModal .profile {
	margin: 0 0 0.5em;
	padding: 1em 0.5em;
	background: #33b5e5;
	background: -moz-linear-gradient(top, #33b5e5 0%, #0099cc 100%);
	background: -webkit-linear-gradient(top, #33b5e5 0%,#0099cc 100%);
	background: linear-gradient(to bottom, #33b5e5 0%,#0099cc 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33b5e5', endColorstr='#0099cc',GradientType=0 );
	
	color: #fff;
	text-align: center;
}

.block.user .topModal .profile .name,
.block.user .topModal .profile .title {
	font-size: 22px;
	line-height: 1.2em;
	color: #fff;
}
.block.user .topModal .profile .title {
	font-size: 16px;
	opacity: 0.8;
}


.block.user .apInputModal {
	color: #666;
}