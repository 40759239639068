.apTabsWrapper {
    width: 100%;
    height: auto;
    margin: 1em 0;
}

.apTabsWrapper .apTabLinks {
    position: relative;
}

.apTabsWrapper .apTabLinks:after {
    content: '';
    display: block;
    clear: both;
}

.apTabsWrapper .apTabLinks:before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #ddd;
}

.apTabsWrapper .apTabLinks .apTabLink {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 16px;
    color: #666;
    position: relative;
    z-index: 2;
    float: left;
    padding: 0.5em 1.5em 0.2em;
    margin: 0;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.apTabsWrapper .apTabLinks .apTabLink.align-right { float: right; }

.apTabsWrapper .apTabLinks .apTabLink:hover {
    color: #0099CC;
}

.apTabsWrapper .apTabLinks .apTabLink.selected {
    color: #0099CC;
    border-color: #33b5e5;
}

.apTabsWrapper .apTabLinks .apTabLink.disabled {
    color: #D3D3D3;
    cursor: default;
}

.apTabsWrapper .apTabLinks .apTabLink svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
    vertical-align: middle;
    margin: -3px 7px 0;
}

.apTabsWrapper .apTabLinks .apTabLink .apTabBadge {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    min-width: 20px;
    padding: 0 5px;
    background: #33b5e5;
    border-radius: 999px;
    margin-left: 5px;

    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    vertical-align: top;
}

.apTabsWrapper .apTabLinks .apTabLink.badge-color-green .apTabBadge { background: #4caf50; }
.apTabsWrapper .apTabLinks .apTabLink.badge-color-red .apTabBadge { background: #ff4444; }
.apTabsWrapper .apTabLinks .apTabLink.badge-color-orange .apTabBadge { background: #FF8800; }
.apTabsWrapper .apTabLinks .apTabLink.badge-color-yellow .apTabBadge { background: #ffbb33; }
.apTabsWrapper .apTabLinks .apTabLink.badge-color-black .apTabBadge { background: #000; }

.apTabsWrapper .apTabLinks .apTabLink .apTabBadge.color-green { background: #4caf50; }
.apTabsWrapper .apTabLinks .apTabLink .apTabBadge.color-red { background: #ff4444; }
.apTabsWrapper .apTabLinks .apTabLink .apTabBadge.color-orange { background: #FF8800; }
.apTabsWrapper .apTabLinks .apTabLink .apTabBadge.color-yellow { background: #ffbb33; }
.apTabsWrapper .apTabLinks .apTabLink .apTabBadge.color-silver { background: #ccc; }
.apTabsWrapper .apTabLinks .apTabLink .apTabBadge.color-black { background: #000; }

.apTabsWrapper .apTabLinks .apTabLink.withIcon .apTabBadge { left: 10px; }

.apTabsWrapper .apTabLinks .apTabLink .apTabBadge.zero { background: #ccc; }

.apTabsWrapper .apTabLinks.size-small .apTabLink { font-size: 14px; padding-left: 1em; padding-right: 1em; }
.apTabsWrapper .apTabLinks.size-small .apTabLink svg { width: 16px; height: 16px; margin: -3px 5px 0; }

.apTabsWrapper .apTabLinks.size-large .apTabLink { font-size: 18px; padding-left: 2em; padding-right: 2em;}
.apTabsWrapper .apTabLinks.size-large .apTabLink svg { width: 24px; height: 24px; margin: -3px 10px 0; }

.apTabsWrapper .apTabLinks.count-1 .apTabLink { max-width: 100%; }
.apTabsWrapper .apTabLinks.count-2 .apTabLink { max-width: 50%; }
.apTabsWrapper .apTabLinks.count-3 .apTabLink { max-width: 33.33%; }
.apTabsWrapper .apTabLinks.count-4 .apTabLink { max-width: 25%; }
.apTabsWrapper .apTabLinks.count-5 .apTabLink { max-width: 20%; }
.apTabsWrapper .apTabLinks.count-6 .apTabLink { max-width: 16.66%; }
.apTabsWrapper .apTabLinks.count-7 .apTabLink { max-width: 14.28%; }
.apTabsWrapper .apTabLinks.count-8 .apTabLink { max-width: 12.5%; }

.apTabsWrapper .apTabLinks.narrow .apTabLink { 
    padding-left: 1em;
    padding-right: 1em; 
    letter-spacing: -1px;
}

.apTabsWrapper .apTabLinks.fullWidth .apTabLink {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    max-width: none !important;
}
.apTabsWrapper .apTabLinks.fullWidth.count-1 .apTabLink { width: 100%; }
.apTabsWrapper .apTabLinks.fullWidth.count-2 .apTabLink { width: 50%; }
.apTabsWrapper .apTabLinks.fullWidth.count-3 .apTabLink { width: 33.33%; }
.apTabsWrapper .apTabLinks.fullWidth.count-4 .apTabLink { width: 25%; }
.apTabsWrapper .apTabLinks.fullWidth.count-5 .apTabLink { width: 20%; }
.apTabsWrapper .apTabLinks.fullWidth.count-6 .apTabLink { width: 16.66%; }
.apTabsWrapper .apTabLinks.fullWidth.count-7 .apTabLink { width: 14.28%; }
.apTabsWrapper .apTabLinks.fullWidth.count-8 .apTabLink { width: 12.5%; }



.apTabsWrapper .apTab { display: none; }
.apTabsWrapper .apTab.selected { display: block; }


.apTabsWrapper .apTabLinks .mobileNav {
    display: none;
}


@media all and (max-width: 600px) {


    .apTabsWrapper .apTabLinks {
        height: 40px;
        margin-bottom: 2em;
    }

    .apTabsWrapper .apTabLinks:before {
        display: none;
    }

    .apTabsWrapper .apTabLinks .apTabLink {
        position: absolute;
        top: 0;
        left: 40px;
        width: calc( 100% - 80px ) !important;
        max-width: none !important;
        text-align: center !important;
        height: 40px;
        float: none;
        opacity: 0;
        pointer-events: none;
        border-bottom: none;
    }

    .apTabsWrapper .apTabLinks .apTabLink.selected {
        opacity: 1;
    }

    .apTabsWrapper .apTabLinks .mobileNav {
        display: block;
    }

    .apTabsWrapper .apTabLinks .mobileNav svg.prevTab,
    .apTabsWrapper .apTabLinks .mobileNav svg.nextTab {
        position: absolute;
        top: 50%;
        left: 0;
        width: 40px;
        height: 40px;
        padding: 10px;
        fill: #33b5e5;

        -webkit-transform: translateY(-50%);
           -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
             -o-transform: translateY(-50%);
                transform: translateY(-50%);
    }

    .apTabsWrapper .apTabLinks .mobileNav svg.nextTab { left: auto; right: 0; }

    .apTabsWrapper .apTabLinks .mobileNav table.lines {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border-spacing: 3px 0;
        border-collapse: separate;
    }

    .apTabsWrapper .apTabLinks .mobileNav table.lines td {
        height: 5px;
        background: #ddd;
    }

    .apTabsWrapper .apTabLinks .mobileNav table.lines td.selected { background: #33b5e5; }

    .apTabsWrapper .apTabLinks .apTabLink .apTabBadge { display: none; }
}



