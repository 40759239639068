.apFormPageFooter {
	padding: 1em;
	padding-right: 180px;
	margin: 1em 0 0;
	border-top: 2px solid #eee;
	background: #fff;
	position: relative;
}
.ApStickyBar.sticky .apFormPageFooter { border-top-color: #33b5e5; }

.apFormPageFooter .info {
	min-height: 42px; /* same as save button height */
	padding-left: 35px;
	position: relative;
}

.apFormPageFooter .info.error { color: #CC0000; }

.apFormPageFooter .info svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 24px;
	height: 24px;
}

.apFormPageFooter .info.unsaved svg { fill: #FF8800; }
.apFormPageFooter .info.error svg { fill: #ff4444; }

.apFormPageFooter .apButton { margin: 0; }

.apFormPageFooter .apButton.save {
	position: absolute;
	top: 1em;
	right: 1em;
}