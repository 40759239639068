
#storageMassExport .apBox {
    margin: 0;

}

#storageMassExport .fieldsTable {
    width: 100%;
}

#storageMassExport .fieldsTable td.tdindent {
    max-width: 40px;
    width: 40px;
    min-width: 40px;
}

#storageMassExport .fieldsTable td.tdcheck {
    padding: 0.5em;
    max-width: 40px;
    width: 40px;
    min-width: 40px;
}

#storageMassExport .fieldsTable .headerrow,
#storageMassExport .fieldsTable .fieldrow {
    border: 1px solid #eee;
    border-left: 3px solid #eee;
}

#storageMassExport .fieldsTable .headerrow .headerSmall {
    font-size: 0.8em;
    font-weight:normal;
}

#storageMassExport .fieldsTable td.tdname {
    padding: 0.5em;
}

#storageMassExport .fieldsTable th {
    padding: 0.5em;
    background-color: #eee;
}

#storageMassExport .footer {
    padding: 0.5em;
    margin: 1em 0 0;
    border-top: 2px solid #eee;
    background: #fff;
    position: relative;
    text-align: right;
}

#storageMassExport .ApStickyBar.sticky .footer { border-top-color: #33b5e5; }

#storageMassExport .topInfo {
    padding: 1em 1em 0 1em;
}
#storageMassExport .footerCounts {
    position: absolute;
    left: 6px;
    top: 6px;

}
#storageMassExport .footerCounts tr td:first-child {
    padding-right: 1em;
}
#storageMassExport .footerCounts tr td:last-child {
    font-weight: 700;
}

