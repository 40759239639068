.VerticalScrollerWrapper {
	width: 100%;
	height: 100%;
	position: relative;
}

.VerticalScrollerWrapper .VerticalScroller {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
}

/* Only works with safari/crome */
.VerticalScrollerWrapper.noScrollBar .VerticalScroller::-webkit-scrollbar { display: none; }

.VerticalScrollerWrapper .shadowTop,
.VerticalScrollerWrapper .shadowBottom { display: none; }

.VerticalScrollerWrapper.srcollShadows .shadowTop,
.VerticalScrollerWrapper.srcollShadows .shadowBottom {
	content: '';
	display: block;
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 30px;
	background: -moz-linear-gradient(top, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#00000000',GradientType=0 );
	opacity: 1;
	-webkit-transition: opacity 1s ease, height 1s ease;
	   -moz-transition: opacity 1s ease, height 1s ease;
	    -ms-transition: opacity 1s ease, height 1s ease;
	     -o-transition: opacity 1s ease, height 1s ease;
			transition: opacity 1s ease, height 1s ease;
	pointer-events: none;
}
.VerticalScrollerWrapper.srcollShadows .shadowBottom {
	top: auto;
	bottom: 0;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=0 );
}

.VerticalScrollerWrapper.srcollShadows .shadowTop.atTop,
.VerticalScrollerWrapper.srcollShadows .shadowBottom.atBottom {
	opacity: 0;
	height: 0;
}
