
.componentTooltip {
}

.componentTooltip table tr {
    border-bottom: 1px solid #ccc;
}
.componentTooltip table td {
    padding: 0.5em 0.2em;
}

.componentTooltip .calcTable,
.componentTooltip .infoTable {
    width: 100%;
    margin-bottom: 1em;
}

.componentTooltip .calcTable .header,
.componentTooltip .infoTable .header {
    font-weight: 700;
    padding: 0.5em 0;
}

.componentTooltip .calcTable .infoLabel,
.componentTooltip .infoTable .infoLabel {
    text-align: left;
    padding-right: 1em;
}

.componentTooltip .calcTable .valueText,
.componentTooltip .infoTable .valueText {
    text-align: left;
}
.componentTooltip .calcTable .infoUnit {
    text-align: left;
}

.componentTooltip .calcTable .valueNumber,
.componentTooltip .infoTable .valueNumber {
    text-align: right;
    font-weight: 700;
}

.componentTooltip .noCalc {
    text-align: left;
    padding-bottom: 1em;
    font-weight: 700;
}

