table.apInputStack {
	width: 100%;
}

table.apInputStack td {
	vertical-align: top;
}

table.apInputStack .apAddon {
	font-family: 'Roboto', Arial, sans-serif;
	display: block;
	margin: 0;
	padding: 0 10px;
	line-height: 42px;
	border: 1px solid #ccc;
	border-bottom-width: 3px;
	background: #f5f5f5;
}

table.apInputStack .apAddon.inline {
	padding: 20px 10px 2px;
	line-height: 20px;
}

table.apInputStack .apAddon.noLeftBorder { border-left: 0; }
table.apInputStack .apAddon.noRightBorder { border-right: 0; }
table.apInputStack .apAddon.noPadding { padding: 0; }

.apInputBlock {
	width: 100%;
	height: auto;
	position: relative;
	margin: 0 0 10px;
}

.apInputBlock .apSelect {
	width: 100%;
	position: relative;
}

.apInputBlock input,
.apInputBlock textarea,
.apInputBlock .apSelect select {
	width: 100%;
	height: auto;
	border: 1px solid #ccc;
	border-bottom-width: 3px;
	padding: 20px 10px 2px;

	font-family: 'Roboto-Light', Arial, sans-serif;
	font-size: 16px;
	line-height: 20px;
	color: #000;

	-webkit-transition: all 0.2s ease;
	   -moz-transition: all 0.2s ease;
	    -ms-transition: all 0.2s ease;
	     -o-transition: all 0.2s ease;
	        transition: all 0.2s ease;
}

.apInputBlock textarea {
	padding: 25px 10px 5px;
	line-height: 26px;
	resize: none;
}

.apInputBlock .apSelect select {
	height: 46px;
	padding: 0 40px 0 10px;
	line-height: 42px;
	background: transparent;
	outline: 0;
	border-radius: 0;

	-webkit-appearance: none;
	   -moz-appearance: none;
		-ms-appearance: none;
			appearance: none;
}

.apInputBlock .apSelect svg.dropdownArrow {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 20px;
	height: 20px;
	pointer-events: none;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.apInputBlock input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fffde7 inset;
    /* -webkit-text-fill-color: #0099CC !important; */
}

.apInputBlock input:focus,
.apInputBlock textarea:focus {
	outline: 0;
}

.apInputBlock.no-label input { padding: 11px 10px; }

.apInputBlock .fileWrapper {
	width: 100%;
	height: auto;
	border: 1px solid #ccc;
	border-bottom-width: 3px;
	padding: 7px 10px 0;
}

.apInputBlock .fileWrapper input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.apInputBlock .fileWrapper label.fileLabel {
	display: block;
	line-height: 30px;
	color: #aaa;
	cursor: pointer;
}

.apInputBlock.filled .fileWrapper label.fileLabel { color: #666; }
.apInputBlock .fileWrapper label.fileLabel:hover { color: #33b5e5; }

.apInputBlock .fileWrapper .removeFile {
	position: absolute;
	top: 50%;
	right: 10px;
	cursor: pointer;
	opacity: 0.5;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.apInputBlock .fileWrapper .removeFile:hover {
	opacity: 1;
}

.apInputBlock.disabled .fileWrapper { pointer-events: none; }

.apInputBlock .labels {
	position: absolute;
	z-index: 10;
	top: 1px;
	left: 10px;
	right: 10px;
	bottom: 3px;
	overflow: hidden;
	pointer-events: none;
}

.apInputBlock .labels label {
	position: absolute;
	z-index: 11;
	top: 0;
	left: 0;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	font-family: 'Roboto', Arial, sans-serif;

	-webkit-transition: transform 0.3s ease, opacity 0.3s ease;
	   -moz-transition: transform 0.3s ease, opacity 0.3s ease;
	    -ms-transition: transform 0.3s ease, opacity 0.3s ease;
	     -o-transition: transform 0.3s ease, opacity 0.3s ease;
	        transition: transform 0.3s ease, opacity 0.3s ease;
}

.apInputBlock label.large {
	font-size: 16px;
	line-height: 42px;
	left: 5px;
	color: #888;
}

.apInputBlock label.small {
	font-size: 12px;
	line-height: 20px;
	color: #999;
	-webkit-transform: translateY(-100%);
	   -moz-transform: translateY(-100%);
	    -ms-transform: translateY(-100%);
	     -o-transform: translateY(-100%);
	        transform: translateY(-100%);
}

.apInputBlock.focused label.large,
.apInputBlock.filled label.large,
.apInputBlock.readOnly label.large {
	-webkit-transform: translateY(100%);
	   -moz-transform: translateY(100%);
	    -ms-transform: translateY(100%);
	     -o-transform: translateY(100%);
	        transform: translateY(100%);
}

.apInputBlock.focused label.small,
.apInputBlock.filled label.small,
.apInputBlock.readOnly label.small {
	-webkit-transform: translateY(0);
	   -moz-transform: translateY(0);
	    -ms-transform: translateY(0);
	     -o-transform: translateY(0);
	        transform: translateY(0);
}


.apInputBlock.textarea .labels { left: 1px; right: 1px; }
.apInputBlock.textarea label.large { padding-left: 9px; }
.apInputBlock.textarea label.small {
	background: rgba(255,255,255,0.9);
	border-bottom: 1px solid #eee;
	width: 100%;
	padding-left: 9px;
}
.apInputBlock.textarea.alignRight .labels label.large,
.apInputBlock.textarea.alignRight .labels label.small { 
	padding-left: 0; 
	padding-right: 9px;
	text-align: right;
}

.apInputBlock.textarea.focused label.large,
.apInputBlock.textarea.filled label.large,
.apInputBlock.textarea.readOnly label.large { opacity: 0; }


.apInputBlock .labels label.small .charCounter {
	position: absolute;
	top: 0;
	right: 10px;

	font-family: 'Roboto-Light', Arial, sans-serif;
	color: #999;
}
.apInputBlock.alignRight .labels label.small .charCounter { right: auto; left: 10px; }


.apInputBlock.focused input,
.apInputBlock.focused textarea,
.apInputBlock.focused .apSelect select,
.apInputBlock.focused .fileWrapper { border-bottom-color: #33b5e5; }
.apInputBlock.focused .labels label.small { color: #33b5e5; }

.apInputBlock.disabled input,
.apInputBlock.disabled textarea,
.apInputBlock.disabled .apSelect select,
.apInputBlock.disabled .fileWrapper { background: #eee; color: #999; cursor: not-allowed; }
.apInputBlock.disabled .labels label { color: #999; }

.apInputBlock.readOnly input,
.apInputBlock.readOnly textarea { background: #f9f9f9; }

.apInputBlock.alignRight input,
.apInputBlock.alignRight textarea,
.apInputBlock.alignRight .apSelect select,
.apInputBlock.alignRight .fileWrapper { text-align: right; }
.apInputBlock.alignRight .labels label { left: auto; right: 0; }

.apInputBlock.alignRight .apSelect select { padding: 0 10px 0 40px; }
.apInputBlock.alignRight .apSelect svg.dropdownArrow { right: auto; left: 10px; }

.apInputBlock .loader {
	position: absolute;
	z-index: 50;
	top: 50%;
	left: 50%;
	display: block;
	width: 30px;
	height: 30px;
	border: 3px solid #999;
	border-left-color: transparent;
	border-top-color: transparent;
	border-radius: 999px;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}



.apInputBlock svg.validationIcon {
	position: absolute;
	z-index: 100;
	top: 50%;
	right: 10px;
	width: 24px;
	height: 24px;
	pointer-events: none;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.apInputBlock.alignRight svg.validationIcon {
	right: auto;
	left: 10px;
}

.apInputBlock .apSelect + svg.validationIcon { top: 45%; right: 32px; }
.apInputBlock.alignRight .apSelect + svg.validationIcon { right: auto; left: 32px; }



.apInputBlock .apTooltip {
    display: block;
    position: absolute;
    z-index: 999999;
    bottom: calc( 100% + 10px );
    left: 50%;
    background: #000;

    color: #fff;
    font-size: 14px;
    line-height: 1.2em;
    white-space: nowrap;

    padding: 0.5em;
    pointer-events: none;
    opacity: 0;
    -webkit-transform: translate(-50%, 10px);
       -moz-transform: translate(-50%, 10px);
        -ms-transform: translate(-50%, 10px);
         -o-transform: translate(-50%, 10px);
            transform: translate(-50%, 10px);

    -webkit-transition: transform 0.4s ease, opacity 0.4s ease;
       -moz-transition: transform 0.4s ease, opacity 0.4s ease;
        -ms-transition: transform 0.4s ease, opacity 0.4s ease;
         -o-transition: transform 0.4s ease, opacity 0.4s ease;
            transition: transform 0.4s ease, opacity 0.4s ease;
}

.apInputBlock .apTooltip:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-top-color: #000;
    border-width: 5px;
    margin-left: -5px;
}

.apInputBlock.focused .apTooltip {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
       -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
         -o-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}


.apInputBlock svg.calendarIcon {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 24px;
	height: 24px;

	fill: #999; 
	pointer-events: none;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.apInputBlock.focused svg.calendarIcon { fill: #33b5e5; }

.apInputBlock svg.clearIcon {
	position: absolute;
	top: 50%;
	right: 45px;

	width: 24px;
	height: 24px;
	fill: #999; 
	cursor: pointer;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.apInputBlock svg.clearIcon:hover { fill: #666; }

/* Hide calendar after date have been picked */
.apInputBlock .rdt.hideCalendar .rdtPicker { display: none; }

.apInputBlock .rdt .rdtPicker { 
	width: 100%;
	min-width: 250px;
	max-width: 400px;
	padding: 0;
	border: 0;
	border-top: 3px solid #0099CC;
	border-bottom: 3px solid #0099CC;
	box-shadow: 0 3px 10px rgba(0,0,0,0.3);
}

.apInputBlock .rdt .rdtPicker:after {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #0099CC;
	border-width: 10px;
	margin-left: -10px;
} 
.apInputBlock .rdt .rdtPicker thead th { background: #e3f2fd; border-bottom: 1px solid #90caf9; color: #0099CC; }

.apInputBlock .rdt .rdtPicker thead th.rdtPrev,
.apInputBlock .rdt .rdtPicker thead th.rdtSwitch,
.apInputBlock .rdt .rdtPicker thead th.rdtNext { background: #0099CC; color: #fff; padding: 5px 0;}

.apInputBlock .rdt .rdtPicker thead th.rdtPrev,
.apInputBlock .rdt .rdtPicker thead th.rdtNext { width: 14.28%; }

.apInputBlock .rdt .rdtPicker thead th.rdtPrev:hover,
.apInputBlock .rdt .rdtPicker thead th.rdtSwitch:hover,
.apInputBlock .rdt .rdtPicker thead th.rdtNext:hover { background: #33b5e5; }

.apInputBlock .rdt .rdtPicker tbody td { text-align: center; vertical-align: middle; }
.apInputBlock .rdt .rdtPicker tbody td.rdtDay,
.apInputBlock .rdt .rdtPicker tbody td.rdtMonth,
.apInputBlock .rdt .rdtPicker tbody td.rdtYear { border: 1px solid #eee; }

.apInputBlock .rdt .rdtPicker tbody td.rdtOld,
.apInputBlock .rdt .rdtPicker tbody td.rdtNew { color: #ccc; }

.apInputBlock .rdt .rdtPicker tbody td.rdtActive { background: #007E33; }
.apInputBlock .rdt .rdtPicker tbody td.rdtDisabled { background: #eee; border-color: #ccc; }



.apInputBlock.headermode { margin: 0; }
.apInputBlock.headermode .apSelect { margin: 0; }
.apInputBlock.headermode .apSelect select {
	border: none;
	border-bottom: 3px solid #ccc;
	height: 40px;
	line-height: 37px;
	font-size: 125%;
}
.apInputBlock.headermode:hover .apSelect select { border-color: #33b5e5; }



.apInputBlock.validator-error input,
.apInputBlock.validator-error textarea,
.apInputBlock.validator-error select { border-bottom-color: #ff4444; }
.apInputBlock.validator-error .labels label { color: #ff4444; }
table.apInputStack .apAddon.validator-error { border-bottom-color: #ff4444; background: #ffcdd2; }

.apInputBlock.validator-warning input,
.apInputBlock.validator-warning textarea,
.apInputBlock.validator-warning select { border-bottom-color: #FF8800; }
.apInputBlock.validator-warning .labels label { color: #FF8800; }
table.apInputStack .apAddon.validator-warning { border-bottom-color: #FF8800; background: #ffe0b2; }


.apInputBlock.validator-loading input,
.apInputBlock.validator-loading textarea,
.apInputBlock.validator-loading select { border-bottom-color: #DAA838; }
.apInputBlock.validator-loading .labels label { color: #DAA838; }
table.apInputStack .apAddon.validator-loading { border-bottom-color: #DAA838; background: #ffe0b2; }


.apInputBlock.validator-success input,
.apInputBlock.validator-success textarea,
.apInputBlock.validator-success select { border-bottom-color: #4caf50; }
.apInputBlock.validator-success .labels label { color: #4caf50; }
table.apInputStack .apAddon.validator-success { border-bottom-color: #4caf50; background: #e8f5e9; }

.apInputBlock.validator-error svg.validationIcon,
.apInputBlock.validator-error svg.calendarIcon { fill: #ff4444; }
.apInputBlock.validator-warning svg.validationIcon,
.apInputBlock.validator-warning svg.calendarIcon { fill: #FF8800; }
.apInputBlock.validator-loading svg.validationIcon,
.apInputBlock.validator-loading svg.calendarIcon { fill: #DAA838; }
.apInputBlock.validator-success svg.validationIcon,
.apInputBlock.validator-success svg.calendarIcon { fill: #4caf50; }


table.apInputStack .apAddon.button {
	cursor: pointer;
	padding: 0;
	text-align: center;
	-webkit-user-select: none;
       -moz-user-select: none;
    	-ms-user-select: none; 
    		user-select: none; 
}

table.apInputStack .apAddon.button:hover {
	background: #eee;
	border-bottom-color: #33b5e5;
}

table.apInputStack .apAddon .apTooltip {
	font-family: 'Roboto-Light', Arial, sans-serif;
}


@media all and (max-width: 600px) {

	.apInputBlock .apSelect select { padding: 0 25px 0 10px; }
	.apInputBlock.alignRight .apSelect select { padding: 0 10px 0 25px; }
	
	.apInputBlock .apSelect svg.dropdownArrow {
		right: 4px;
		width: 16px;
		height: 16px;
	}
	.apInputBlock.alignRight .apSelect svg.dropdownArrow { left: 4px; }

	.apInputBlock.headermode .apSelect select { font-size: 120%; }
}



