.apDropdown {
    display: inline-block;
    position: relative;
    color: #666;
}

.apDropdown .apDropdownButton {
    cursor: pointer;
}

.apDropdown .apDropdownButton .defaultButton {
    background: #fff;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.apDropdown.disabled .apDropdownButton {
    opacity: 0.5;
    pointer-events: none;
}

.apDropdown .apDropdownButton .defaultButton svg {
    width: 20px;
    height: 20px;
}

.apDropdown.open .apDropdownButton .defaultButton {
    box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
    color: #33b5e5;
}

.apDropdown .apDropdownButton .defaultButton:hover { border-color: #33b5e5; }

.apDropdown .apDropdownMenu {
    position: absolute;
    z-index: 12;
    right: 0;
    width: 200px;
    background: #fff;
    box-shadow: 0 5px 20px rgba(0,0,0,0.2);

    top: calc( 100% + 5px );
    border-top: 3px solid #33b5e5;

    opacity: 0;
    pointer-events: none;

    -webkit-transition: opacity 0.4s ease;
       -moz-transition: opacity 0.4s ease;
        -ms-transition: opacity 0.4s ease;
         -o-transition: opacity 0.4s ease;
            transition: opacity 0.4s ease;
}

.apDropdown .apDropdownMenu.openTop {
    top: auto;
    bottom: calc( 100% + 5px );
    border-top: 0;
    border-bottom: 3px solid #33b5e5;
}


.apDropdown .apDropdownMenu.openRight {
    left: 0;
    right: auto;
}

.apDropdown.open .apDropdownMenu {
    opacity: 1;
    pointer-events: auto;
}

.apDropdown .apDropdownMenu:after {
    bottom: 100%;
    right: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: #33b5e5;
    border-width: 10px;
    margin-left: -10px;

}

.apDropdown .apDropdownMenu.openTop:after {
    bottom: auto;
    top: 100%;
    border-bottom-color: transparent;
    border-top-color: #33b5e5;
}

.apDropdown .apDropdownMenu.openRight:after {
    right: auto;
    left: 10px;
}

.apDropdown .action {
    padding: 0.5em 0.5em 0.5em 36px;
    position: relative;
    text-align: left;
    
    font-size: 14px;

    cursor: pointer;
}

.apDropdown .action:hover {
    background: #f9f9f9;
}

.apDropdown .action.disabled {
    color: #ccc;
    cursor: not-allowed;
}
.apDropdown .action.disabled:hover { background: none; }

.apDropdown .action svg {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 16px;
    height: 16px;

    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.apDropdown .divider {
    min-height: 0.5em;
    border-bottom: 1px solid #eee;
    margin-bottom: 0.5em;
    position: relative;
    text-align: left;
}

.apDropdown .divider.withLabel { margin-top: 0.5em; }

.apDropdown .divider span {
    position: absolute;
    font-size: 13px;
    line-height: 1em;
    color: #aaa;
    background: #fff;
    padding: 0 0.5em;
}

.apDropdown.fullWidth {
    width: 100%;
}

