
.apButtonGroup {
	margin: 0 0 1em;
}
.apButtonGroup > .apButton {
	margin: 0 0.2em 0 0.5em;
}

.apButotnGroup.right { text-align: right; }
.apButtonGroup.right > .apButton { margin: 0 0 0.5em 0.2em; }


.apButtonGroup.center { text-align: center; }
.apButtonGroup.center > .apButton { margin: 0 0.2em 0.5em; }



.apButton {
	display: inline-block;
	margin: 0 0 0.5em;
	border: 1px solid rgba(0,0,0,0.2);
	padding: 0.5em 1.5em;
	background: #e5e5e5;
	position: relative;

	font-family: 'Roboto', Arial, sans-serif;
	font-size: 16px;
	line-height: 24px;
	color: #666;
	text-align: center;
	vertical-align: middle;

	-webkit-border-radius: 5px;
			border-radius: 5px;
	-webkit-box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.1);
			box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.1);

	-webkit-transition: background 0.4s ease;
       -moz-transition: background 0.4s ease;
        -ms-transition: background 0.4s ease;
         -o-transition: background 0.4s ease;
            transition: background 0.4s ease;
}

.apButton .holder { pointer-events: none; }
.apButton.loading .holder { opacity: 0.2; }

.apButton:hover { background: #eee; }

.apButton:focus { 
	outline: 0;
	-webkit-filter: brightness(90%);
    		filter: brightness(90%);
	border-color: rgba(0,0,0,0.4);
}

.apButton:active {
	-webkit-box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);
			box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);
}
.apButton:active .holder {
	-webkit-transform: translateY(2px);
	   -moz-transform: translateY(2px);
	    -ms-transform: translateY(2px);
	     -o-transform: translateY(2px);
	        transform: translateY(2px);
}

.apButton:disabled { 
	color: #ccc;
	cursor: not-allowed;
	background: #e5e5e5;
}

.apButton:disabled .holder { opacity: 0.7; }

.apButton.color-green { background: #4caf50; }
.apButton.color-blue { background: #2196f3; }
.apButton.color-orange { background: #ff9800; }
.apButton.color-red { background: #e53935; }
.apButton.color-white { background: #fff; }

.apButton.color-green:hover { background: #66bb6a; }
.apButton.color-blue:hover { background: #42a5f5; }
.apButton.color-orange:hover { background: #ffa726; }
.apButton.color-red:hover { background: #f44336; }
.apButton.color-white:hover { background: #f5f5f5; }

.apButton.color-green:focus,
.apButton.color-blue:focus,
.apButton.color-orange:focus,
.apButton.color-red:focus { border-color: #000; }

.apButton.color-green,
.apButton.color-blue,
.apButton.color-orange, 
.apButton.color-red { color: #fff; }

.apButton.color-green:disabled { background: #c8e6c9; }
.apButton.color-blue:disabled { background: #b3e5fc; }
.apButton.color-orange:disabled { background: #ffe0b2; }
.apButton.color-red:disabled { background: #ffcdd2; }
.apButton.color-white:disabled { background: #fff; }

.apButton.narrow { padding-left: 0.5em; padding-right: 0.5em; }

.apButton.full-width {
	padding-left: 0;
	padding-right: 0;
	width: 100%;
}

.apButton.ghost { 
	border-color: transparent; 
	background: transparent;
	-webkit-box-shadow: none;
			box-shadow: none;
}

.apButton.ghost:active,
.apButton.ghost:hover,
.apButton.ghost:focus {
	-webkit-box-shadow: none;
			box-shadow: none;
	background: rgba(0,0,0,0.05);
	border-color: rgba(0,0,0,0.1);
}

.apButton svg {
	display: inline-block;
	width: 24px;
	height: 24px;
	fill: currentColor;
	vertical-align: middle;
	margin: -3px 0.2em 0;
}
.apButton.iconTop svg {
	display: block;
	margin: 0 auto 0.2em;
}


.apButton.size-xtiny { font-size: 12px; line-height: 16px;  padding: 1px 6px; }
.apButton.size-tiny { font-size: 12px; line-height: 16px; }
.apButton.size-small { font-size: 14px; line-height: 20px; }
.apButton.size-large { font-size: 20px; line-height: 28px; }
.apButton.size-giant { font-size: 24px; line-height: 32px; }

.apButton.size-xtiny svg { width: 16px; height: 16px; }
.apButton.size-tiny svg { width: 16px; height: 16px; }
.apButton.size-small svg { width: 20px; height: 20px; }
.apButton.size-large svg { width: 28px; height: 28px; }
.apButton.size-giant svg { width: 32px; height: 32px; }

.apButton.no-margin { margin: 0; }

.apButton.highlight { overflow: hidden; }
.apButton.highlight:after {
	content: '';
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	width: 50px;
	height: 50px;
	margin: -25px 0 0 -25px;
	background: rgba(255,255,255,0.3);
	pointer-events: none;
	opacity: 0.5;
	border-radius: 999px;

	-webkit-animation: apButtonHighlightAnimation 1s infinite;
       -moz-animation: apButtonHighlightAnimation 1s infinite;
         -o-animation: apButtonHighlightAnimation 1s infinite;
        -ms-animation: apButtonHighlightAnimation 1s infinite;
            animation: apButtonHighlightAnimation 1s infinite;
}

@keyframes apButtonHighlightAnimation {
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(3); opacity: 0; }
}
@-moz-keyframes apButtonHighlightAnimation {
    0% { -moz-transform: scale(1); opacity: 1; }
    100% { -moz-transform: scale(3); opacity: 0; }
}

@-webkit-keyframes apButtonHighlightAnimation {
    0% { -webkit-transform: scale(1); opacity: 1; }
    100% { -webkit-transform: scale(3); opacity: 0; }
}

@-o-keyframes apButtonHighlightAnimation {
    0% { -o-transform: scale(1); opacity: 1; }
    100% { -o-transform: scale(3); opacity: 0; }
}

@-ms-keyframes apButtonHighlightAnimation {
    0% { -ms-transform: scale(1); opacity: 1;}
    100% { -ms-transform: scale(3); opacity: 0; }
}

@media all and (max-width: 500px) {
	.apButton {
		padding: 2px;
	}
}