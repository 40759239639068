

#app > .mainWrapper {
    margin-left: 300px;
    -webkit-transition: margin 0.4s ease;
       -moz-transition: margin 0.4s ease;
        -ms-transition: margin 0.4s ease;
         -o-transition: margin 0.4s ease;
            transition: margin 0.4s ease;
}

#app > .mainWrapper.sideNavMinified { margin-left: 50px; } 


#navSide {
    position: fixed;
    z-index: 500;
    top: 50px;
    left: 0;
    bottom: 0;
    width: 300px;
    background: #eee;
    border-right: 1px solid #ccc;
    color: #666;

    -webkit-transition: width 0.4s ease;
       -moz-transition: width 0.4s ease;
        -ms-transition: width 0.4s ease;
         -o-transition: width 0.4s ease;
            transition: width 0.4s ease;
}

.sideNavMinified #navSide {
    position: absolute;
    width: 50px;
    bottom: auto;
}

#navSide > .top,
#navSide > .bottom {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ccc;
}

#navSide > .bottom {
    border-bottom: none;
    border-top: 1px solid #ccc;
    text-align: right;
    position: relative;
}

#navSide> .top .searchField {
    position: relative;
    margin: 0 5px;
}

#navSide> .top .searchField input {
    width: 100%;
    height: 40px;
    padding: 0 1em 0 45px;
    line-height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px 0;
    background: #f9f9f9;

    -webkit-transition: padding 0.4s ease, width 0.4s ease, box-shadow 0.4s ease;
       -moz-transition: padding 0.4s ease, width 0.4s ease, box-shadow 0.4s ease;
        -ms-transition: padding 0.4s ease, width 0.4s ease, box-shadow 0.4s ease;
         -o-transition: padding 0.4s ease, width 0.4s ease, box-shadow 0.4s ease;
            transition: padding 0.4s ease, width 0.4s ease, box-shadow 0.4s ease;
}

#navSide> .top .searchField input:focus {
    background: #fff;
    outline: 0;
}

#navSide> .top .searchField svg {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 20px;
    height: 20px;
    fill: #999;
    pointer-events: none;
}

.sideNavMinified #navSide> .top .searchField input {
    padding: 0 3px 0 35px;
}

.sideNavMinified #navSide> .top .searchField input:focus {
    padding: 0 1em 0 35px;
    width: 200px;
    -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.5);
            box-shadow: 0 0 50px 0 rgba(0,0,0,0.5);
}

.sideNavMinified #navSide> .top .searchField svg {
    left: 10px;
}

#navSide > .bottom .toggleButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background: #ddd;
    border-left: 1px solid #ccc;
    cursor: pointer;
}

#navSide > .bottom .toggleButton:hover { background: #f9f9f9; }

#navSide > .bottom .toggleButton svg {
    display: block;
    width: 20px;
    height: 20px;
    margin: 15px auto;

    -webkit-transition: transform 0.4s ease;
       -moz-transition: transform 0.4s ease;
        -ms-transition: transform 0.4s ease;
         -o-transition: transform 0.4s ease;
            transition: transform 0.4s ease;
}
.sideNavMinified #navSide > .bottom .toggleButton { border-left: 0; border-bottom: 1px solid #ccc; }
.sideNavMinified #navSide > .bottom .toggleButton svg {
    -webkit-transform: rotate(180deg);
       -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg);
}

#navSide > .middle {
    width: 100%;
    height: calc(100% - 102px ); /* 102px = total height (+borders) of .top and .bottom */
}

.sideNavMinified #navSide > .middle { 
    height: auto;
    min-height: calc(100% - 152px); /* 152px = total height (+borders) of .top, .bottom and #navTop */
}


@media all and (max-width: 999px) {

    #app > .mainWrapper { margin-left: 50px; } 

    #navSide {
        box-shadow: 0 0 20px rgba(0,0,0,0.3);
    }

    .sideNavMinified #navSide {
        box-shadow: none;
    }

}




/* ---------- Side navigation ---------- */

/*
#sidenav-button:checked + .container .sidenav {
    left: 0px;
    -webkit-transition: all 0.25s ease-out 0s;
    -moz-transition: all 0.25s ease-out 0s;
    transition: all 0.25s ease-out 0s;
 }

 #navbar-top {
     background-color: #3c8dbc !important;
     color: #ffffff !important;
 }



.sidenav-header {
    color: #444444 !important;
    font-size: 2rem;
}

#sidenav-button:checked + .container .toggle {
    -webkit-transition: all 0.5s ease-out 0.5s;
    -moz-transition: all 0.5s ease-out 0.5s;
    transition: all 0.5s ease-out 0.5s;
 }

 .nav-header {
     color: #ffffff !important;
 }
#sidenav-label {
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 2em;
    cursor: pointer;
    z-index: 100;
}

#sidenav-button:checked {
    left:220px;
    -webkit-transition: all 0.5s ease-out 0.5s;
    -moz-transition: all 0.5s ease-out 0.5s;
    transition: all 0.5s ease-out 0.5s;
}

.sidenav {
    font-weight: lighter;
    font-size: 0.9em;
    flex-direction: column;
    min-height: 100%;
    background-color: #f9fafc;
    width: 210px;
    left: 0px;
    top: 40px;
    padding-top: 10px;
    transition: all 0.75s cubic-bezier(0.9, 0, 0.2, 0.7);
    transform-origin: bottom left;
    border: 1px solid #CCCCCC;
}


.sidenav-align-center {
    color:#CCCCCC;
    font-family: 'Roboto';
    font-size: 1.2em;
    text-align: left;
    overflow-x: hidden;
    white-space: nowrap;
    width: 100%;
    height: 100%;
}
.sidenav-top {
    height: 30px;
    width: 210px;
}
.nav-link {
    color: #ffffff !important;
}

.nav-link:hover {
    background-color: #3482b4 !important;
}

.nav-link {
    font-size: 1.25em;
}
.badge {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: 0;
    background-color: #ed615f;
}
.navbar-right {
    margin-right: 1em;

}

.footer {
    margin-top: 45px;
    margin-left: 20px;
    width: 100%;
}

*/