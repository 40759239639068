#reportsPage .apInputBlock .apSelect select.dateMode{
    background: #f5f5f5;
}
#reportsPage .reportDates table td:first-child {
    width: 200px;
}
#reportsPage .depthInputContainer {
    margin-bottom: 10px;
}
#reportsPage .buttonBar button {
    margin-right: 0.5em;
}

#reportsPage .buttonBar .rightButton {
    float: right;
    display: inline-block;
}

#reportsPage .item {
    cursor: pointer;
}
