.apSelect {
	position: relative;
}

.apSelect .apInputBlock svg.validationIcon {
	right: 32px;
}

.apSelect .selectedItems {
	border: 1px solid #ccc;
	border-bottom: 0;
	background: #f9f9f9;
	padding-bottom: 5px;
}

.apSelect .selectedItems .item {
	display: inline-block;
	margin: 5px 5px 0;	
	font-size: 14px;
	line-height: 1.5em;
	position: relative;
}

.apSelect .selectedItems .item .remove {
	position: absolute;
	top: -4px;
	right: 4px;
	width: 24px;
	height: 24px;
	background: #ff4444;
	cursor: pointer;

	-webkit-border-radius: 999px;
			border-radius: 999px;

	-webkit-box-shadow: 0 3px 5px 0 rgba(0,0,0,0.5);
			box-shadow: 0 3px 5px 0 rgba(0,0,0,0.5); 

	opacity: 0;

	-webkit-transition: opacity 0.4s ease;
	   -moz-transition: opacity 0.4s ease;
	    -ms-transition: opacity 0.4s ease;
	     -o-transition: opacity 0.4s ease;
	        transition: opacity 0.4s ease;

}

.apSelect .selectedItems .item:hover .remove { opacity: 1; }
.apSelect .selectedItems .item .remove:hover { background: #CC0000; }

.apSelect .selectedItems .item .remove svg {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 16px;
	height: 16px;
	margin-top: -8px;
	margin-left: -8px;
	fill: #fff;
}

.apSelect .selectedItems .item .presetUser {
	background: #eee;
	border: 1px solid #ccc;
	border-bottom-width: 3px;
	padding: 0.2em 0.5em 0.2em 30px;
	position: relative;
}

.apSelect .selectedItems .item .presetUser.active {
	border-bottom-color: #4caf50;
}

.apSelect .selectedItems .item .presetUser.inactive {
	border-bottom-color: #ff4444;
}

.apSelect .selectedItems .item .presetUser .image {
	position: absolute;
	top: 50%;
	left: 3px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.apSelect .selectedItems .item .presetUsergroup {
	background: #eee;
	border: 1px solid #ccc;
	border-bottom-width: 3px;
	padding: 0.2em 0.5em;
}

.apSelect .selectedItems .item .presetUsergroup .modules {
	display: inline-block;
	margin-left: 0.5em;
	background: #4285F4;
	color: #fff;
	border-radius: 99px;
	font-size: 12px;
	line-height: 1.5em;
	padding: 0 0.5em;
}

.apSelect .selectedItems .item .presetProject {
	background: #eee;
	border: 1px solid #ccc;
	border-bottom-width: 3px;
	padding: 0.2em 0.5em 0.2em 0.5em;
	position: relative;
}

.apSelect .selectedItems .item .presetProject .code {
    font-size: 9px;
    line-height: 9px;
    padding-left: 4px;
}

.apSelect .selectedItems .item .presetComponent {
	background: #eee;
	border: 1px solid #ccc;
	border-bottom-width: 3px;
	padding: 0.2em 0.5em 0.2em 30px;
	position: relative;
}

.apSelect .selectedItems .item .presetComponent .icon {
	position: absolute;
	top: 50%;
	left: 3px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}
.apSelect .selectedItems .item .presetComponent .code {
    font-size: 9px;
    line-height: 9px;
    padding-left: 4px;
}
.apSelect .selectedItems .item .presetComponent.plan {
	border-bottom-color: #ffbb33;
}

.apSelect .selectedItems .item .presetComponent.active {
	border-bottom-color: #4caf50;
}

.apSelect .selectedItems .item .presetComponent.frozen {
	border-bottom-color: #33b5e5;
}

.apSelect .selectedItems .item .presetComponent.removed {
	border-bottom-color: #ff4444;
}


.apSelect .clear {
	position: absolute;
	top: 10px;
	right: 45px;
	cursor: pointer;
	opacity: 0.5;
}
.apSelect .clear:hover { opacity: 1; }
.apSelect .clear svg {
	width: 20px;
	height: 20px;
}

.apSelect.multiselect .clear { right: 10px; }

.apSelect svg.arrow {
	position: absolute;
	bottom: 14px;
	right: 10px;
	width: 20px;
	height: 20px;

	-webkit-transition: transform 0.4s ease;
	   -moz-transition: transform 0.4s ease;
	    -ms-transition: transform 0.4s ease;
	     -o-transition: transform 0.4s ease;
	        transition: transform 0.4s ease;

	pointer-events: none;
}

.apSelect.resultsShowing svg.arrow {
	-webkit-transform: rotate(90deg);
	   -moz-transform: rotate(90deg);
	    -ms-transform: rotate(90deg);
	     -o-transform: rotate(90deg);
	        transform: rotate(90deg);
}


.apSelect .results {
	position: absolute;
	z-index: 101;
	top: 100%;
	left: 0;
	width: 100%;
	height: auto;
	background: #f9f9f9;
	border: 1px solid #ccc;
	border-top: 0;
	display: none;

	max-height: 300px;
	overflow: auto;
}

.apSelect .results.show { display: block; }

.apSelect .results .noResults {
	text-align: center;
	color: #999;
	padding: 0.5em;
}

.apSelect .results > .item {
	padding: 0.5em;
	padding-left: 30px;
	border-left: 5px solid #eee;
	position: relative;
}
.apSelect .results > .item:hover { background: #fff; }
.apSelect .results > .item.selected { border-left-color: #33b5e5; }
.apSelect .results > .item.active { color: #0099CC; background: #e3f2fd; }

.apSelect .results > .item .selectedIcon { 
	position: absolute; 
	top: 50%; 
	left: 7px;
	width: 16px;
	height: 16px;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.apSelect .results > .item .presetUser {
	padding-left: 40px;
	line-height: 1.2em;
	position: relative;
}

.apSelect .results > .item .presetUser .image {
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
	/*
	width: 42px;
	height: 42px;
	margin-top: -21px;
	background-color: #ddd;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	border-radius: 999px;

	-webkit-box-shadow:inset 0 0 5px 0 rgba(0,0,0,0.5);
			box-shadow:inset 0 0 5px 0 rgba(0,0,0,0.5);
	*/
}

.apSelect .results > .item .presetUser .inactiveMsg {
	position: absolute;
	right: 1em;
	top: 50%;

	font-size: 14px;
	color: #CC0000;

	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}


.apSelect .results > .item .presetUsergroup {
	padding-left: 40px;
	line-height: 1.2em;
	position: relative;
}

.apSelect .results > .item .presetUsergroup .icon {
	position: absolute;
	left: 0;
	top: 50%;
	width: 24px;
	height: 24px;
	margin-top: -12px;
}

.apSelect .results > .item .presetUsergroup .modules {
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);

	background: #4285F4;
	color: #fff;
	border-radius: 99px;
	font-size: 12px;
	line-height: 1.5em;
	padding: 0 0.5em;
}


.apSelect .results > .item .presetProject,
.apSelect .results > .item .presetProjectWork {
	line-height: 1.2em;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

/* .apSelect .results > .item .presetProject .customer {
	position: absolute;
	top: 50%;
	right: 1em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
} */

.apSelect .results > .item .presetProjectWork .work {
	position: absolute;
	top: 50%;
	right: 0;
	background: #f9f9f9;
	padding: 0.5em;
	font-size: 14px;
	border: 1px solid #ccc;
	border-left-width: 3px;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.apSelect .results > .item .presetProjectWork .work svg {
	width: 16px;
	height: 16px;
	margin: -3px 5px 0 0;
}

@media all and (max-width: 1200px) {

	.apTabs .apSelect .results {
		position: relative;
	}

}

@media all and (max-width: 600px) {
	.apSelect .results > .item .presetProject .customer,
	.apSelect .results > .item .presetProjectWork .work {
		position: relative;
		top: auto;
		right: auto;
		-webkit-transform: none;
		   -moz-transform: none;
		    -ms-transform: none;
		     -o-transform: none;
		        transform: none;
		font-size: 12px;

		display: block;
		width: calc( 100% - 1em );
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.apSelect .results > .item .presetProject .customer svg {
		width: 12px;
		height: 12px;
	}

	.apSelect .results {
		z-index: 600;
	}
	
	.apSelect .results > .item {
		padding-left: 15px;
	}
}

.apSelect .results > .item .presetStorageLocation,
.apSelect .results > .item .presetStorageLocation {
	line-height: 1.2em;
	position: relative;
}

.apSelect .results > .item .presetStorageLocation .address {
    color: #999;
    padding-left: 8px;
    font-size: 0.8em;
}

.apSelect .results > .item .presetComponent {
	line-height: 1.2em;
	position: relative;
}

.apSelect .results > .item .presetComponent .unit {
	position: absolute;
	top: 50%;
	right: 1em;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.apSelect .results > .item .presetComponentDetail {
    line-height: 1.2em;
    position: relative;
}

.apSelect .results > .item .componentDetailIcon{
    position: absolute;
    left: 0;
    top: 50%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}
.apSelect .results > .item .componentDetailText {
    margin-left: 30px;
}


.apSelect .results > .item .presetComponentDetail .name {
    padding-right: 0.5em;
}

.apSelect .results > .item .presetComponentDetail .value {
    font-weight: 700;
}

.apSelect .results > .item .presetComponentDetail .balanceLocations {
    position: absolute;
    right: 1em;
    top: 0;
    font-size: 12px;
}

.apSelect .results > .item .presetComponentDetail .balance {
    position: absolute;
    right: 1em;
    top: 14px;
    font-size: 14px;
}

.apSelect .results > .item .presetComponentDetail .balanceFree {
    position: absolute;
    right: 1em;
    top: 26px;
    font-size: 10px;
}

.apSelect .results > .item .presetComponentDetail .sIdentifier {
    font-size: 0.8em;
    padding-left: 2em;
    padding-top: 4px;
    padding-bottom: 4px;
}

.apSelect .results > .item .presetStorageOrder .createdContainer {
    position: absolute;
    right: 1em;
    top: 0;
    font-size: 0.8em;
}
.apSelect .results > .item .presetStorageOrder .createdContainer .value {
    margin-left: 0.5em;
}

.apSelect .results > .item .presetStorageOrder .status {
    position: absolute;
    right: 1em;
    top: 1.3em;
    webkit-box-shadow: 0 0 1px 1px #FFF;
           box-shadow: 0 0 1px 1px #FFF;
}

.apSelect .results > .item .presetStorageOrder .typeContainer .type {
    font-weight: 700;
    font-size: 1.1em;
}
.apSelect .results > .item .presetStorageOrder .typeContainer .title {
    margin-left: 0.5em;
}
.apSelect .results > .item .presetStorageOrder .typeContainer .value {
    margin-left: 0.2em;
    font-weight: 700;
    font-size: 0.9em;
}
.apSelect .results > .item .presetStorageOrder .detailTable {
    margin-left: 2em;
}
.apSelect .results > .item .presetStorageOrder .detailTable td {
    font-size: 0.8em;
}
.apSelect .results > .item .presetStorageOrder .detailTable td.title {
    padding-right: 2em;
}
.apSelect .results > .item .presetStorageOrder .detailTable td svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
}

