#navTop .blocks > .block.languages .button .flag {
	display: inline-block;
	width: 24px;
    height: 24px;
    margin: -3px 0 0 0;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid #fff;
	border-radius: 999px;
}

#navTop .blocks > .block.languages .topModal { text-align: center; }

#navTop .blocks > .block.languages .topModal button {
	display: inline-block;
	width: 30%;
	margin: 1%;
	background: transparent;
	border: 1px solid #eee;
	border-radius: 10px;
}

#navTop .blocks > .block.languages .topModal button:focus,
#navTop .blocks > .block.languages .topModal button:hover {
	outline: none;
	border-color: #ccc;
}

#navTop .blocks > .block.languages .topModal button.selected {
	border-color: #33b5e5;
	color: #33b5e5;
}
#navTop .blocks > .block.languages .topModal button .flag {
	display: block;
	width: 40px;
	height: 40px;
	margin: 5px auto;
	border: 1px solid #ccc;
	border-radius: 10px;
	background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#navTop .blocks > .block.languages .topModal button .text {
	font-size: 14px;
}