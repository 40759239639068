.componentInstanceTable .listTooltipText {
    text-align: left;
    font-weight: 700;
}

.componentInstanceTable .listTooltipValue {
    font-size: 0.8em;
    text-align: right;
    padding-left: 1em;
}

.componentInstanceTable .notPrimary .listTooltipText {
    opacity: 0.6;
}

.componentInstanceTable .editButton {
    color: #CCC;
}

.componentInstanceTable .calculatableUnit {
    padding-left: 0.5em;
    font-weight: bold;
    right: 0.5em;
    position: absolute;
}

.componentInstanceTable .rt-td.clickable:hover .codeCell { color: #CCC; }
.componentInstanceTable .codeCell {
    font-size: 0.9em;
    font-weight: 700;
    color: #666;
}

.componentInstanceTable .nameCell {
    position: relative;
    width: 100%;
    height: 100%;
}

.componentInstanceTable .nameCell .nameCellName {
    margin-bottom: 0.8em;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.componentInstanceTable .rt-td.clickable:hover .nameCellCode { color: #CCC; }
.componentInstanceTable .nameCell .nameCellCode {
    position: absolute;
    font-size: 0.8em;
    left: 1em;
    top: 1.3em;
    color: #AAA;
    max-width: calc( 100% - 1em );
    overflow: hidden;
    text-overflow: ellipsis;
}

.componentInstanceTable .imageCellImage {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.componentInstanceTable .locationContainer {
    position: relative;
    width: 100%;
    height: 100%;
}
.componentInstanceTable .locationContainer .locationBalance {
    margin-bottom: 0.8em;
}
.componentInstanceTable .locationContainer .locationCount {
    position: absolute;
    font-size: 0.8em;
    right: 0;
    top: 1.3em;
    color: #AAA;
}

.componentInstanceTable .supplierNameContainer {
    position: relative;
    width: 100%;
    height: 100%;
}
.componentInstanceTable .supplierNameContainer .supplierName {
    margin-bottom: 0.8em;
}
.componentInstanceTable .supplierNameContainer .supplierCount {
    position: absolute;
    font-size: 0.8em;
    left: 0;
    top: 1.3em;
    color: #AAA;
}
.componentInstanceTable .rt-tr .editableCell {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    border: 1px solid #eee;
    border-bottom-width: 3px;
    padding: 0 20px 0 0.2em;
    overflow: hidden;
    text-overflow: ellipsis;
}
.componentInstanceTable .rt-tr svg.indicator { display: none; }

.componentInstanceTable .rt-tr .editableCell svg.indicator {
    display: block;
    position: absolute;
    top: 50%;
    right: 4px;
    width: 12px;
    height: 12px;
    fill: #33b5e5;
    -webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
            transform: translateY(-50%);
    opacity: 0;
}
.componentInstanceTable .rt-tr .editableCell:hover { color: #0099CC; border-bottom-color: #33b5e5; }
.componentInstanceTable .rt-tr .editableCell:hover svg.indicator { opacity: 1; }
