.apTooltipper,
.apInputTooltipper {
    position: relative;
    display: inline-block;
}

.apTooltipper.block { display: block; }

.apTooltipper .apTooltip,
.apInputTooltipper .apTooltipHover,
.apInputTooltipper .apTooltipActive,
.apInputTooltipper .apTooltipFocus {
    display: block;
    position: absolute;
    z-index: 999999;
    bottom: calc( 100% + 10px );
    left: 50%;
    background: #000;

    color: #fff;
    font-size: 14px;
    line-height: 1.2em;
    white-space: nowrap;
    text-align: center;

    padding: 0.5em;
    pointer-events: none;
    opacity: 0;
    -webkit-transform: translate(-50%, 10px);
       -moz-transform: translate(-50%, 10px);
        -ms-transform: translate(-50%, 10px);
         -o-transform: translate(-50%, 10px);
            transform: translate(-50%, 10px);

    -webkit-transition: transform 0.4s ease, opacity 0.4s ease;
       -moz-transition: transform 0.4s ease, opacity 0.4s ease;
        -ms-transition: transform 0.4s ease, opacity 0.4s ease;
         -o-transition: transform 0.4s ease, opacity 0.4s ease;
            transition: transform 0.4s ease, opacity 0.4s ease;
}

.apTooltipper .apTooltip:after,
.apInputTooltipper .apTooltipHover:after,
.apInputTooltipper .apTooltipActive:after,
.apInputTooltipper .apTooltipFocus:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-top-color: #000;
    border-width: 5px;
    margin-left: -5px;
}

.apTooltipper:hover .apTooltip,
.apTooltipper:hover .apTooltip.position-bottom,
.apInputTooltipper input:hover + .apTooltipHover,
.apInputTooltipper input:active + .apTooltipActive,
.apInputTooltipper input:focus + .apTooltipFocus,
.apInputTooltipper select:hover + .apTooltipHover,
.apInputTooltipper select:active + .apTooltipActive,
.apInputTooltipper select:focus + .apTooltipFocus,
.apInputTooltipper textarea:hover + .apTooltipHover,
.apInputTooltipper textarea:active + .apTooltipActive,
.apInputTooltipper textarea:focus + .apTooltipFocus {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
       -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
         -o-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}


/* Delay tooltip appearing */
.apTooltipper:hover .apTooltip.delayed,
.apInputTooltipper input:hover + .apTooltipHover.delayed,
.apInputTooltipper input:active + .apTooltipActive.delayed,
.apInputTooltipper input:focus + .apTooltipFocus.delayed,
.apInputTooltipper select:hover + .apTooltipHover.delayed,
.apInputTooltipper select:active + .apTooltipActive.delayed,
.apInputTooltipper select:focus + .apTooltipFocus.delayed,
.apInputTooltipper textarea:hover + .apTooltipHover.delayed,
.apInputTooltipper textarea:active + .apTooltipActive.delayed,
.apInputTooltipper textarea:focus + .apTooltipFocus.delayed {
    -webkit-transition-delay: 0.5s;
       -moz-transition-delay: 0.5s;
        -ms-transition-delay: 0.5s;
         -o-transition-delay: 0.5s;
            transition-delay: 0.5s;
}

/* Multiline (lots of text) */
.apTooltipper .apTooltip.multiline,
.apInputTooltipper .apTooltipHover.multiline,
.apInputTooltipper .apTooltipActive.multiline,
.apInputTooltipper .apTooltipFocus.multiline {
    width: 300px;
    line-height: 1.5em;
    white-space: normal;
}


/* Tooltip at bottom */
.apTooltipper .apTooltip.position-bottom,
.apInputTooltipper .apTooltipHover.position-bottom,
.apInputTooltipper .apTooltipActive.position-bottom,
.apInputTooltipper .apTooltipFocus.position-bottom {
    bottom: auto;
    top: calc(100% + 10px );
    -webkit-transform: translate(-50%, -10px);
       -moz-transform: translate(-50%, -10px);
        -ms-transform: translate(-50%, -10px);
         -o-transform: translate(-50%, -10px);
            transform: translate(-50%, -10px);
}
.apTooltipper .apTooltip.position-bottom:after,
.apInputTooltipper .apTooltipHover.position-bottom:after,
.apInputTooltipper .apTooltipActive.position-bottom:after,
.apInputTooltipper .apTooltipFocus.position-bottom:after {
    top: auto;
    bottom: 100%;
    border-top-color: transparent;
    border-bottom-color: #000;
}




/* Tooltip at left */
.apTooltipper .apTooltip.position-left,
.apInputTooltipper .apTooltipHover.position-left,
.apInputTooltipper .apTooltipActive.position-left,
.apInputTooltipper .apTooltipFocus.position-left {
    bottom: 50%;
    left: auto;
    right: calc( 100% + 10px );
    -webkit-transform: translate(10px, 50%);
       -moz-transform: translate(10px, 50%);
        -ms-transform: translate(10px, 50%);
         -o-transform: translate(10px, 50%);
            transform: translate(10px, 50%);
}
.apTooltipper .apTooltip.position-left:after,
.apInputTooltipper .apTooltipHover.position-left:after,
.apInputTooltipper .apTooltipActive.position-left:after,
.apInputTooltipper .apTooltipFocus.position-left:after {
    left: 100%;
    top: 50%;
    border-top-color: transparent;
    border-left-color: #000;
    margin-left: 0;
    margin-top: -5px;
}


/* Tooltip at right */
.apTooltipper .apTooltip.position-right,
.apInputTooltipper .apTooltipHover.position-right,
.apInputTooltipper .apTooltipActive.position-right,
.apInputTooltipper .apTooltipFocus.position-right {
    bottom: 50%;
    left: calc( 100% + 10px );
    -webkit-transform: translate(-10px, 50%);
       -moz-transform: translate(-10px, 50%);
        -ms-transform: translate(-10px, 50%);
         -o-transform: translate(-10px, 50%);
            transform: translate(-10px, 50%);
}
.apTooltipper .apTooltip.position-right:after,
.apInputTooltipper .apTooltipHover.position-right:after,
.apInputTooltipper .apTooltipActive.position-right:after,
.apInputTooltipper .apTooltipFocus.position-right:after {
    left: auto;
    right: 100%;
    top: 50%;
    border-top-color: transparent;
    border-right-color: #000;
    margin-left: 0;
    margin-top: -5px;
}

/* Tooltip at topleft */
.apTooltipper .apTooltip.position-topleft,
.apInputTooltipper .apTooltipHover.position-topleft,
.apInputTooltipper .apTooltipActive.position-topleft,
.apInputTooltipper .apTooltipFocus.position-topleft {
    left: 0;
    -webkit-transform: translate(0, 10px);
       -moz-transform: translate(0, 10px);
        -ms-transform: translate(0, 10px);
         -o-transform: translate(0, 10px);
            transform: translate(0, 10px);
}
.apTooltipper .apTooltip.position-topleft:after,
.apInputTooltipper .apTooltipHover.position-topleft:after,
.apInputTooltipper .apTooltipActive.position-topleft:after,
.apInputTooltipper .apTooltipFocus.position-topleft:after {
    left: 10px;
}

/* Tooltip at topright */
.apTooltipper .apTooltip.position-topright,
.apInputTooltipper .apTooltipHover.position-topright,
.apInputTooltipper .apTooltipActive.position-topright,
.apInputTooltipper .apTooltipFocus.position-topright {
    left: auto;
    right: 0;
    -webkit-transform: translate(0, 10px);
       -moz-transform: translate(0, 10px);
        -ms-transform: translate(0, 10px);
         -o-transform: translate(0, 10px);
            transform: translate(0, 10px);
}
.apTooltipper .apTooltip.position-topright:after,
.apInputTooltipper .apTooltipHover.position-topright:after,
.apInputTooltipper .apTooltipActive.position-topright:after,
.apInputTooltipper .apTooltipFocus.position-topright:after {
    left: auto;
    right: 10px;
}

.apTooltipper:hover .apTooltip.position-topright,
.apTooltipper:hover .apTooltip.position-topleft,
.apInputTooltipper input:hover + .apTooltipHover.position-topright,
.apInputTooltipper input:hover + .apTooltipHover.position-topleft,
.apInputTooltipper input:active + .apTooltipActive.position-topright,
.apInputTooltipper input:active + .apTooltipActive.position-topleft,
.apInputTooltipper input:focus + .apTooltipFocus.position-topright,
.apInputTooltipper input:focus + .apTooltipFocus.position-topleft,
.apInputTooltipper select:hover + .apTooltipHover.position-topright,
.apInputTooltipper select:hover + .apTooltipHover.position-topleft,
.apInputTooltipper select:active + .apTooltipActive.position-topright,
.apInputTooltipper select:active + .apTooltipActive.position-topleft,
.apInputTooltipper select:focus + .apTooltipFocus.position-topright,
.apInputTooltipper select:focus + .apTooltipFocus.position-topleft,
.apInputTooltipper textarea:hover + .apTooltipHover.position-topright,
.apInputTooltipper textarea:hover + .apTooltipHover.position-topleft,
.apInputTooltipper textarea:active + .apTooltipActive.position-topright,
.apInputTooltipper textarea:active + .apTooltipActive.position-topleft,
.apInputTooltipper textarea:focus + .apTooltipFocus.position-topright,
.apInputTooltipper textarea:focus + .apTooltipFocus.position-topleft {
    -webkit-transform: translate(0, 0);
       -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
         -o-transform: translate(0, 0);
            transform: translate(0, 0);
}


.apTooltipper:hover .apTooltip.position-left,
.apTooltipper:hover .apTooltip.position-right,
.apInputTooltipper input:hover + .apTooltipHover.position-left,
.apInputTooltipper input:hover + .apTooltipHover.position-right,
.apInputTooltipper input:active + .apTooltipActive.position-left,
.apInputTooltipper input:active + .apTooltipActive.position-right,
.apInputTooltipper input:focus + .apTooltipFocus.position-left,
.apInputTooltipper input:focus + .apTooltipFocus.position-right,
.apInputTooltipper select:hover + .apTooltipHover.position-left,
.apInputTooltipper select:hover + .apTooltipHover.position-right,
.apInputTooltipper select:active + .apTooltipActive.position-left,
.apInputTooltipper select:active + .apTooltipActive.position-right,
.apInputTooltipper select:focus + .apTooltipFocus.position-left,
.apInputTooltipper select:focus + .apTooltipFocus.position-right,
.apInputTooltipper textarea:hover + .apTooltipHover.position-left,
.apInputTooltipper textarea:hover + .apTooltipHover.position-right,
.apInputTooltipper textarea:active + .apTooltipActive.position-left,
.apInputTooltipper textarea:active + .apTooltipActive.position-right,
.apInputTooltipper textarea:focus + .apTooltipFocus.position-left,
.apInputTooltipper textarea:focus + .apTooltipFocus.position-right {
    -webkit-transform: translate(0, 50%);
       -moz-transform: translate(0, 50%);
        -ms-transform: translate(0, 50%);
         -o-transform: translate(0, 50%);
            transform: translate(0, 50%);
}

/* Coloring tooltip */
.apTooltipper .apTooltip.color-blue,
.apInputTooltipper .apTooltipHover.color-blue,
.apInputTooltipper .apTooltipActive.color-blue,
.apInputTooltipper .apTooltipFocus.color-blue {                         background: #0099CC; }
.apTooltipper .apTooltip.color-blue:after,
.apInputTooltipper .apTooltipHover.color-blue:after,
.apInputTooltipper .apTooltipActive.color-blue:after,
.apInputTooltipper .apTooltipFocus.color-blue:after {                   border-top-color: #0099CC; }
.apTooltipper .apTooltip.position-bottom.color-blue:after,
.apInputTooltipper .apTooltipHover.position-bottom.color-blue:after,
.apInputTooltipper .apTooltipActive.position-bottom.color-blue:after,
.apInputTooltipper .apTooltipFocus.position-bottom.color-blue:after {   border-bottom-color: #0099CC; border-top-color: transparent; }
.apTooltipper .apTooltip.position-left.color-blue:after,
.apInputTooltipper .apTooltipHover.position-left.color-blue:after,
.apInputTooltipper .apTooltipActive.position-left.color-blue:after,
.apInputTooltipper .apTooltipFocus.position-left.color-blue:after {     border-left-color: #0099CC; border-top-color: transparent; }
.apTooltipper .apTooltip.position-right.color-blue:after,
.apInputTooltipper .apTooltipHover.position-right.color-blue:after,
.apInputTooltipper .apTooltipActive.position-right.color-blue:after,
.apInputTooltipper .apTooltipFocus.position-right.color-blue:after {    border-right-color: #0099CC; border-top-color: transparent; }

.apTooltipper .apTooltip.color-green,
.apInputTooltipper .apTooltipHover.color-green,
.apInputTooltipper .apTooltipActive.color-green,
.apInputTooltipper .apTooltipFocus.color-green {                        background: #007E33; }
.apTooltipper .apTooltip.color-green:after,
.apInputTooltipper .apTooltipHover.color-green:after,
.apInputTooltipper .apTooltipActive.color-green:after,
.apInputTooltipper .apTooltipFocus.color-green:after {                  border-top-color: #007E33; }
.apTooltipper .apTooltip.position-bottom.color-green:after,
.apInputTooltipper .apTooltipHover.position-bottom.color-green:after,
.apInputTooltipper .apTooltipActive.position-bottom.color-green:after,
.apInputTooltipper .apTooltipFocus.position-bottom.color-green:after {  border-bottom-color: #007E33; border-top-color: transparent; }
.apTooltipper .apTooltip.position-left.color-green:after,
.apInputTooltipper .apTooltipHover.position-left.color-green:after,
.apInputTooltipper .apTooltipActive.position-left.color-green:after,
.apInputTooltipper .apTooltipFocus.position-left.color-green:after {    border-left-color: #007E33; border-top-color: transparent; }
.apTooltipper .apTooltip.position-right.color-green:after,
.apInputTooltipper .apTooltipHover.position-right.color-green:after,
.apInputTooltipper .apTooltipActive.position-right.color-green:after,
.apInputTooltipper .apTooltipFocus.position-right.color-green:after {   border-right-color: #007E33; border-top-color: transparent; }

.apTooltipper .apTooltip.color-orange,
.apInputTooltipper .apTooltipHover.color-orange,
.apInputTooltipper .apTooltipActive.color-orange,
.apInputTooltipper .apTooltipFocus.color-orange {                           background: #FF8800; }
.apTooltipper .apTooltip.color-orange:after,
.apInputTooltipper .apTooltipHover.color-orange:after,
.apInputTooltipper .apTooltipActive.color-orange:after,
.apInputTooltipper .apTooltipFocus.color-orange:after {                     border-top-color: #FF8800; }
.apTooltipper .apTooltip.position-bottom.color-orange:after,
.apInputTooltipper .apTooltipHover.position-bottom.color-orange:after,
.apInputTooltipper .apTooltipActive.position-bottom.color-orange:after,
.apInputTooltipper .apTooltipFocus.position-bottom.color-orange:after {     border-bottom-color: #FF8800; border-top-color: transparent; }
.apTooltipper .apTooltip.position-left.color-orange:after,
.apInputTooltipper .apTooltipHover.position-left.color-orange:after,
.apInputTooltipper .apTooltipActive.position-left.color-orange:after,
.apInputTooltipper .apTooltipFocus.position-left.color-orange:after {       border-left-color: #FF8800; border-top-color: transparent; }
.apTooltipper .apTooltip.position-right.color-orange:after,
.apInputTooltipper .apTooltipHover.position-right.color-orange:after,
.apInputTooltipper .apTooltipActive.position-right.color-orange:after,
.apInputTooltipper .apTooltipFocus.position-right.color-orange:after {      border-right-color: #FF8800; border-top-color: transparent; }

.apTooltipper .apTooltip.color-red,
.apInputTooltipper .apTooltipHover.color-red,
.apInputTooltipper .apTooltipActive.color-red,
.apInputTooltipper .apTooltipFocus.color-red {                          background: #CC0000; }
.apTooltipper .apTooltip.color-red:after,
.apInputTooltipper .apTooltipHover.color-red:after,
.apInputTooltipper .apTooltipActive.color-red:after,
.apInputTooltipper .apTooltipFocus.color-red:after {                    border-top-color: #CC0000; }
.apTooltipper .apTooltip.position-bottom.color-red:after,
.apInputTooltipper .apTooltipHover.position-bottom.color-red:after,
.apInputTooltipper .apTooltipActive.position-bottom.color-red:after,
.apInputTooltipper .apTooltipFocus.position-bottom.color-red:after {    border-bottom-color: #CC0000; border-top-color: transparent; }
.apTooltipper .apTooltip.position-left.color-red:after,
.apInputTooltipper .apTooltipHover.position-left.color-red:after,
.apInputTooltipper .apTooltipActive.position-left.color-red:after,
.apInputTooltipper .apTooltipFocus.position-left.color-red:after {      border-left-color: #CC0000; border-top-color: transparent; }
.apTooltipper .apTooltip.position-right.color-red:after,
.apInputTooltipper .apTooltipHover.position-right.color-red:after,
.apInputTooltipper .apTooltipActive.position-right.color-red:after,
.apInputTooltipper .apTooltipFocus.position-right.color-red:after {     border-right-color: #CC0000; border-top-color: transparent; }


.apTooltipper .apTooltip small,
.apInputTooltipper .apTooltipHover small,
.apInputTooltipper .apTooltipActive small,
.apInputTooltipper .apTooltipFocus small {
    font-size: 80%;
    color: rgba(255,255,255,0.6);
}


